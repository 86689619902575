.gpt-new {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  /* justify-content: space-between; */
  overflow: hidden;
  align-items: center;
  padding: 0.5rem;
  background: $primary_chatbot;
  align-items: center;
  gap: 1rem;

  .apptitle {
    font-size: 0.7rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
  }

  .appdescription {
    font-size: 0.7rem;
    margin: 1rem;
  }

  .link {
    font-weight: 500;
  }

  .generatebutton {
    position: absolute;
    bottom: 0.9em;
    right: 0;
    color: $primary_chatbot;
    overflow: hidden;
    color: rgb(165, 162, 162);
    background: none;
    padding: 0.3rem;
    border: none;
    display: flex;
  }

  .loadingwheel {
    position: absolute;
    top: -1rem;
    right: 0.25rem;
  }

  .svgicon {
    position: absolute;
    right: 0;
    transform: rotate(90deg);
    width: 1.2em;
    height: 1.2em;
    fill: currentColor;
  }

  .generatebutton:hover {
    // background: #1f2227;
    border-radius: 0.2rem;
  }

  .generatebutton:disabled {
    opacity: 0.9;
    cursor: not-allowed;
    background: none;
  }

  .messagelist {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-radius: 0.5rem;
    position: relative;
    box-sizing: border-box;
    white-space: normal;
    flex-direction: column;
    align-items: flex-start;
  }

  .messageContent {
    padding: 10px;
    border-radius: 8px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
    word-break: break-word;
  }

  /* .messagelist::-webkit-scrollbar {
  display: none;
} */

  .messagelistloading {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
  }

  .usermessage {
    background: $primary_chatbot;
    padding: 1.5rem;
    color: #ececf1;
  }

  .usermessagewaiting {
    padding: 1.5rem;
    color: #ececf1;
    background: linear-gradient(
      to left,
      $primary_chatbot,
      darken($primary_chatbot, 10%),
      $primary_chatbot
    );
    background-size: 200% 200%;
    background-position: -100% 0;
    animation: loading-gradient 2s ease-in-out infinite;
    animation-direction: alternate;
    animation-name: loading-gradient;
  }

  @keyframes loading-gradient {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  .apimessage {
    background: $secondary_chatbot;
    padding: 1.5rem;
    animation: fadein 0.5s;
    position: relative;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .apimessage,
  .usermessage,
  .usermessagewaiting {
    display: flex;
    position: relative;
    color: $default_text_color;
  }

  .markdownanswer {
    line-height: 1.75;
  }

  .markdownanswer a:hover {
    opacity: 0.8;
  }
  /* 
.apimessage:not(:first-of-type) .markdownanswer:last-of-type > p:first-child {
  font-size: 2em;
} */

  .apimessage .markdownanswer > p:last-child a {
    font-size: 1.5em;
    padding: 0.5em 0;
    color: rgb(59, 144, 178) !important;
  }

  .markdownanswer code {
    color: #15cb19;
    font-weight: 500;
    white-space: pre-wrap !important;
  }

  .markdownanswer ol,
  .markdownanswer ul {
    margin: 1rem;
  }

  .boticon,
  .usericon {
    margin-right: 1rem;
    border-radius: 0.1rem;
  }

  .apimessage:not(:first-of-type) .markdownanswer h1,
  .apimessage:not(:first-of-type) .markdownanswer h2,
  .apimessage:not(:first-of-type) .markdownanswer h3,
  .apimessage:not(:first-of-type) .markdownanswer h4 {
    margin: 0.5em auto;
    color: rgba(255, 54, 54, 0.685);
  }

  .apimessage:not(:first-of-type) .markdownanswer img {
    width: auto;
    max-width: 100%;
    max-height: 200px;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1rem 0 0 0;
    flex-direction: column;
    background: transparent;
    width: 85vw;
  }

  .cloud {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background: transparent;
    border-radius: 0.5rem;
    // border: 1px solid #30373d;
    display: flex;
    justify-content: center;
    align-items: center;

    &.home {
      height: 28.7em;
    }
    &.entity {
      // height: 32.1em;
    }
  }

  @media (max-width: 600px) {
    .home {
      width: 90vw;
    }

    .cloud {
      margin: 1vh auto 1vh auto !important;
      height: 57vh;
      width: 95vw;
      margin-left: 2.5vw;
      margin-right: 2.5vw;
      @media (max-width: 768px) {
        height: 80vh;
      }
    }

    .center {
      width: 95vw;
      padding: 0;
      margin-left: 2.5vw;
      margin-right: 2.5vw;
    }
  }

  .pointsnormal {
    width: 90%;
    height: 90%;
  }

  .pointsdim {
    width: 90%;
    height: 90%;
    opacity: 0.25;
  }

  .footer {
    color: #5f6368;
    font-size: 0.8rem;
    margin: 1.5rem;
  }

  .footer a {
    font-weight: 500;
    color: #7a7d81;
  }

  .footer a:hover {
    opacity: 0.8;
  }

  /* Mobile optimization */
  @media (max-width: 600px) {
    .main {
      padding: 0;
    }

    .topnav {
      border: 1px solid black;
      align-items: center;
      padding: 0.85rem 0.75rem 0.85rem 0.75rem;
      margin: 0 !important;
    }

    .navlogo * {
      scale: 0.9;
    }

    .navlogo {
      font-size: 1rem;
      margin: 0 !important;
    }

    .markdownanswer code {
      white-space: pre-wrap !important;
    }

    .footer {
      font-size: 0.7rem;
      width: 100%;
      text-align: center;
    }
  }

  /* Add these styles for the suggestions */
  .suggestions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0.5rem; /* Adjust the gap between suggestion buttons as needed */
    margin-top: 0.5rem; /* Add some spacing between the input field and suggestions */
  }

  .suggestions button {
    background: none;
    border: 1px solid #30373d; /* Add a border to each suggestion button */
    border-radius: 0.5rem;
    color: #ececf1;
    cursor: pointer;
    font-size: 1rem; /* Adjust the font size as needed */
    padding: 0.3rem 1rem;
    transition:
      background-color 0.3s,
      color 0.3s,
      border-color 0.3s;
  }

  .suggestions button:hover {
    background-color: #1f2227; /* Change background color on hover */
    border-color: #1f2227; /* Change border color on hover */
    color: #fff; /* Change text color on hover */
  }

  textarea {
    position: relative !important;
    resize: none !important;
    font-size: 1.1rem !important;
    overflow: hidden !important;
    padding: 0.5rem 1rem !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    background: var(
      --background-color
    ) !important; /* Initial background color */
    color: $default_text_color !important;
    outline: none !important;
    transition: background-color 0.3s !important; /* Smooth transition for background color change */
  }

  textarea::placeholder {
    color: #5f6368;
  }

  .input-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
    gap: 0.5rem;
    justify-content: center;
  }

  .input-form {
    width: 100%;
  }
}
