////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//                                        COLOURS SECTION START
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////

$black: #000000;
$white: #ffffff;

// Yellow
$yellow_lightest: #fffef0;
$yellow_lighter: #fffccc;
$yellow_light: #fff980;
$yellow: #ffd30e;
$yellow_dark: #cfb53b;
$yellow_darker: #b6ac00;

// Red
$red_lighter: #fbeaef;
$red_light: #eeaabe;
$red: #e06287;
$red_dark: #c7496e;
$red_darker: #b64a4a;
$red_darkest: #3b0013fc;

// Green
$green_lighter: #e0f2d9;
$green_light: #b1df9f;
$green: #6cc24a;
$green_dark: #53a931;
$green_darker: #519238;
$green_darkest: #3e6d2a;

// Teal
$teal_lightest_max: #e0f7f3;
$teal_lightest: #e0f7f3;
$teal_lighter: #d8f3ee;
$teal_light: #9fe0d5;
$teal: #40c1ac;
$teal_dark: #27a893;
$teal_darker: #277467;

// violet
$violet_lightest: #f0e9f8;
$violet_lighter: #9693c0;
$violet_light: #716eac;
$violet: #4b4897;
$violet_dark: #3e398b;
$violet_darker: #302a7e;

// Blue
$blue_lighter: #d3eaf8;
$blue_light: #91cbed;
$blue: #62b5e5;
$blue_dark: #499ccc;
$blue_darker: rgb(40, 56, 197);
$blue_darkest: #110050;

// Purple
$purple_lighter: #e9dfec;
$purple_light: #c7afcf;
$purple: #a57fb2;
$purple_dark: #8c6699;
$purple_darker: #785884;

// Orange
$orange_lightest: #fff0e6;
$orange_lighter: #fae6d1;
$orange_light: #f2bf8c;
$orange: #f7941d;
$orange_dark: #e07d10;
$orange_darker: #d86e0f;

// Gray
$gray_darkest: #333333;
$gray_darker: #666666;
$gray_dark: #999999;
$gray: #cccccc;
$gray_light: #dddddd;
$gray_lighter: #eeeeee;
$gray_lightest: #f8f8f8;
$gray_white: #fbfbfb;

//Chatbot colours
$primary_chatbot: $gray_lighter;
$secondary_chatbot: $gray_lightest;

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//                                        COLOURS SECTION END
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////

// PLATFORM DEFAULTS

$background_default: $white;
$background_default_new: $gray_lighter;
$default_text_color: $black;

:root {
  --side_panel_with: 60px;
  --side_panel_header_height: 30%;
  --side_panel_footer_height: 5%;
}

$font_base: 16px;
$main_font: 'El Messiri', sans-serif !default;
$platform_primary_colour: #e0e0e0;
$main_primary_colour: $violet;
$main_primary_colour_light: $violet_light;
$main_secondary_colour: $red_darkest;
$main_secondary_colour_light: $red_light;
$platform_secondary_colour: $orange;
$teriary_colour: $gray_dark;
$destructive_colour: $red_dark;
$cell_width: 60px;
$table_width: 95%;
$number_of_cells: 3;
$indicators_cells_width: 80%;
$title_cell_width: calc($table_width - $indicators_cells_width);
$table_cell_width: calc(($table_width - $title_cell_width) / $number_of_cells);
$third_column_color: #daf9fc;
$fourth_column_color: rgb(245, 220, 242);
$side_panel_width: var(--side_panel_with);
$side_panel_header_height: var(--side_panel_header_height);
$side_panel_footer_height: var(--side_panel_footer_height);
$borders: 2px solid $gray_lighter;
$block_padding: 0.5rem;
$blocks_gap: 1rem;
$sticky_header_height: 40px;
// $entity_height: calc(100% - #{$sticky_header_height});
$entity_height: 98%;
$entity_header_height: 14%;
$entity_header_parameters_height: 40px;
$entity_header_height_full: calc(
  #{$entity_header_height} + #{$entity_header_parameters_height}
);
$entity_data_mapped_height: 29%;
$entity_descriptions_and_chart_height: calc(
  100% - #{$entity_data_mapped_height} - #{$entity_header_height_full}
);
$new_model_width: calc(100vw - #{$side_panel_width});
