body {
  background: $background_default !important;
  min-height: 100vh;
  padding: 0;
  margin: 0 !important;
  font-family: $main_font !important;
  color: $default_text_color;

  *:not(button):not(input) {
    transition: all 0.3s ease;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  td,
  th,
  span,
  a,
  input,
  button,
  option,
  select {
    line-height: normal;
    font-weight: 400;
    color: $default_text_color;
  }
}

.z-max {
  z-index: 9999;
  position: absolute;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  &.secondary {
    color: $main_secondary_colour !important;
  }
  &.white {
    color: $white;
  }
}

h1 {
  font-size: 2.5rem !important;
  font-weight: 600;
  margin: 0;
  padding: 0;
  &.secondary {
    color: $main_secondary_colour !important;
  }
  &.white {
    color: $white;
  }
}

h2 {
  font-size: 1.5rem !important;
  font-weight: 600;
  margin: 0;
  padding: 0;
  &.secondary {
    color: $main_secondary_colour !important;
  }
  &.white {
    color: $white;
  }
}

h3 {
  font-size: 1.25rem !important;
  font-weight: 600;
  margin: 0;
  padding: 0;
  &.secondary {
    color: $main_secondary_colour !important;
  }
  &.white {
    color: $white;
  }
}

button {
  width: 80px;
  height: 30px;
  border-radius: 6px !important;
  margin: 5px;
  text-align: left;
  cursor: pointer !important;

  &.primary {
    outline: 2px solid $main_primary_colour;
    font-weight: 600;
    color: black;
    background: $white !important;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    &:hover:not(:disabled) {
      background: $main_primary_colour !important;
      color: #fff !important;
      * {
        color: #fff !important;
        stroke: #fff !important;
      }
    }
    &.active {
      color: #fff !important;
      background: $main_primary_colour !important;
      * {
        color: #fff !important;
      }
    }
    &.no-hover {
      &:hover {
        background: $main_primary_colour !important;
        color: black !important;
        * {
          color: #000 !important;
        }
      }
    }
  }
  &.secondary {
    outline: 2px solid $main_secondary_colour;
    color: black;
    background: $white !important;
    border: none !important;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    &:hover:not(:disabled) {
      background: $main_secondary_colour !important;
      color: #fff !important;
      * {
        color: #fff !important;
        stroke: #fff !important;
      }
    }
    &.active {
      color: #fff !important;
      background: $main_secondary_colour !important;
      * {
        color: #fff !important;
      }
    }
    &.no-hover {
      &:hover {
        background: $main_secondary_colour !important;
        color: black !important;
        * {
          color: #000 !important;
        }
      }
    }
  }
  &.teriary {
    background: $white;
    color: black;
    outline: 2px solid $teriary_colour !important;
    border: 1px solid transparent !important;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    &:hover {
      background: $teriary_colour !important;
      color: $white !important;
      * {
        color: $white !important;
      }
    }
  }
  &.destructive {
    background: #fff;
    border-color: $destructive_colour;
    color: $destructive_colour !important;
    * {
      color: $black !important;
    }
    &:hover:not(:disabled) {
      background: $destructive_colour;
      color: #fff !important;
      * {
        color: #fff !important;
        stroke: #fff !important;
      }
    }
  }
  &.tab {
    backface-visibility: hidden;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1.3;
    padding: 0.5rem 1rem;
    width: fit-content !important;
    height: auto !important;
    text-decoration: none;
    transform: translateZ(0) scale(1);
    transition: transform 0.2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    &:disabled {
      color: #787878;
      cursor: auto;
    }
    &:not(:disabled) {
      &:hover {
        transform: scale(1.05);
        &:active {
          transform: scale(1.05) translateY(0.125rem);
        }
      }
      &:active {
        transform: translateY(0.125rem);
      }
    }
    &:focus {
      &:before {
        content: '';
        left: calc(-1 * 0.375rem);
        pointer-events: none;
        position: absolute;
        top: calc(-1 * 0.375rem);
        transition: border-radius;
        user-select: none;
      }
      &:not(:focus-visible) {
        outline: 0 solid transparent;
      }
    }
  }
  &.icon {
    width: 30px !important;
    height: 30px !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    > img {
      width: 30px;
      height: 30px;
      margin: 0;
      padding: 0;
    }

    &.destructive {
      background: #fff;
      border-color: $destructive_colour !important;
      color: $destructive_colour !important;
      * {
        color: $white !important;
      }
      &:hover:not(:disabled) {
        background: $destructive_colour;
        color: #fff !important;
        * {
          color: #fff !important;
          stroke: #fff !important;
        }
      }
    }

    &.t {
      &-large {
        width: 40px !important;
        height: 40px !important;
        > img {
          width: 40px;
          height: 40px;
        }
      }
      &-small {
        width: 20px !important;
        height: 20px !important;
        > img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  &.no-btn {
    background-color: transparent !important;
    border: 0;
    font-weight: 400 !important;
    line-height: inherit !important;
    box-shadow: none !important;
    > span {
      text-decoration: underline !important;
    }
    &.no-hover {
      &:hover {
        transform: scale(1) !important;
      }
      > span {
        text-decoration: none !important;
      }
    }
    &.tall {
      height: 40px !important;
    }
    &.no-decoration {
      span,
      i {
        text-decoration: none !important;
      }
      &-icon {
        i {
          text-decoration: none !important;
        }
      }
    }
    &:hover {
      background-color: $gray_lighter !important;
    }
    &:hover,
    &:focus {
      text-decoration: none !important;
      > span {
        text-decoration: none !important;
      }
    }
    &.no-hover {
      &:hover {
        background: transparent !important;
      }
    }
  }
  &.no-hover {
    &:hover {
      background: transparent !important;
      border: none !important;
    }
    &-text {
      &:hover {
        color: inherit !important;
      }
    }
  }
  &.inline {
    // display: inline-block !important;
    width: fit-content !important;

    margin: 0 0.25em !important;
    padding: 0 0.25em !important;
    height: 25px !important;
    font-size: 0.8rem !important;
    &:not(.no-btn) {
      min-width: 70px;
    }
  }
  &.min {
    min-width: 80px !important;
  }
  &.wide {
    width: 140px !important;
  }
  &.wider {
    width: 200px !important;
  }
  &.widest {
    width: 250px !important;
  }
  &.auto {
    width: fit-content !important;
  }
  &.full {
    width: 100% !important;
  }
  &.border {
    border: $borders !important;
    &:hover {
      border: 1px solid $gray !important;
    }
    &-danger {
      border: 1px solid $destructive_colour !important;
      &:hover {
        border: 1px solid darken($destructive_colour, 20%) !important;
      }
    }
  }
  &.t {
    &-left {
      padding-left: 5px !important;
      text-align: left !important;
    }
    &-right {
      text-align: right !important;
    }
  }
  &.select {
    border-radius: 0 !important;
  }
  &:disabled {
    background: transparent;
    border: $borders !important;
    color: $gray_dark !important;
    cursor: not-allowed !important;
    outline: 2px solid inherit !important;
    &:hover {
      background: $gray_lightest !important;
      color: $gray_dark !important;
      border: $borders !important;
    }
    * {
      opacity: 0.7 !important;
    }
  }
  &.no-border {
    border: none !important;
    outline: none !important;

    > * {
      border: none !important;
      outline: none !important;
    }
  }
  &.no-hover {
    &:hover {
      border: none !important;
    }
  }
  &:disabled {
    border-color: $gray_light !important;
    color: $gray_dark !important;
    cursor: not-allowed !important;
    outline: 2px solid inherit !important;
    opacity: 0.7 !important;
    &:hover,
    * &:hover {
      transform: none !important;
      background: $gray_lightest !important;
      border-color: $gray_light !important;
      color: $gray_dark !important;
    }
  }

  &.toggle {
    &.disabled {
      opacity: 0.7;
      border: $borders !important;
      outline: none !important;
      * {
        opacity: 0.9;
      }
    }
    &.enabled {
      opacity: 1;
      outline: none !important;
      border: $borders !important;
      border-color: $main_primary_colour !important;
      * {
        stroke: white !important;
        fill: $main_primary_colour !important;
        background: $main_primary_colour !important;
      }
    }
  }
}

.hover {
  &:hover {
    background: $gray_lighter !important;
    * {
      scale: 1.05;
    }
  }
}

input,
option,
select,
textarea {
  padding: 0px !important;
  text-align: left !important;
  color: $default_text_color !important;
  border-radius: 10px !important;
  border: none !important;
  outline: $gray solid 2px !important;
  box-shadow: 1px 4px 7px $gray_dark !important;
  ::placeholder {
    color: $gray_darker !important;
  }
  &.narrow {
    width: 120px !important;
  }
  &.narrowish {
    width: 100px !important;
  }
  &.narrower {
    width: 80px !important;
  }
  &.narrowest {
    width: 60px !important;
  }
  &.wide {
    width: 200px !important;
  }
  &.wider {
    width: 250px !important;
  }
  &.widest {
    width: 300px !important;
  }
  &.reader {
    border: none !important;
    outline: none !important;
    width: 100%;
    height: 100%;
    box-shadow: none !important;
  }
  &:disabled {
    background: $gray_lightest !important;
    color: $gray_dark !important;
    cursor: not-allowed !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  &.ai {
    //   // Keyframes for the glow animation
    //   @keyframes glow {
    //     0% {
    //       box-shadow:
    //         0 0 5px #0f9,
    //         0 0 10px #0f9;
    //     }
    //     100% {
    //       box-shadow:
    //         0 0 15px #0f9,
    //         0 0 30px #0f9;
    //     }
    //   }
  }
}

input[type='number'] {
  box-shadow: none !important;
}

input,
option,
select {
  height: 25px;
  width: 150px;
  padding-left: 5px !important;

  &.inline {
    display: inline-block !important;
    width: fit-content;
    margin: 1px 0.5em;
    box-shadow: none !important;
    height: 20px !important;
  }
}

textarea.description {
  height: 85% !important;
  width: 100% !important;
  resize: none !important;
  overflow: auto !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 0 !important;
  outline: 0 !important;
}

//   &.local {
//     margin: 1em auto 1em auto !important;
//   }
//   th {
//     padding: 0.8em !important;
//     background-color: $platform_primary_colour;
//     border: 0.5px solid !important;
//     border-bottom: none !important;
//     color: $white;
//   }
//   td {
//     background-color: $white !important;
//     border: 0.5px solid !important;
//     padding: 0.5em !important;
//   }
//   &.ranked {
//     td:first-child {
//       background: $platform_secondary_colour !important;
//       color: $white;
//       font-weight: 800;
//     }
//     &.parent {
//       td:first-child {
//         background: $main_secondary_colour !important;
//       }
//     }
//   }
//   thead.parent th {
//     background-color: $main_primary_colour !important;
//   }
// }
.container-table {
  @media only screen and (min-width: 520px) {
    max-width: 40%;
    min-width: 500px !important;
    margin-left: auto;
    margin-right: auto;
  }
  .wrapper-table {
    display: table !important;
    empty-cells: show !important;
    table-layout: fixed !important;
    width: 100%;
    thead {
      position: relative !important;
      display: block !important;
      width: 100% !important;
      overflow-y: scroll !important;
    }
    tbody {
      display: block !important;
      position: relative !important;
      width: 100% !important;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
      max-height: 50vh !important;
      min-height: 300px !important;
    }
    tr {
      width: 100% !important;
      display: flex !important;
      &.approved > td:first-child {
        font-weight: 600 !important;
      }
      &.pending {
        td {
          color: $teriary_colour;
        }
      }
    }
    thead {
      position: relative !important;
      display: block !important;
      width: 100% !important;
      overflow-y: scroll !important;
    }
    tbody {
      display: block !important;
      position: relative !important;
      width: 100% !important;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
      max-height: 50vh !important;
      min-height: 300px !important;
    }
    @media only screen and (min-width: 481px) {
      tr {
        width: 100% !important;
        display: flex !important;
      }
      th,
      td {
        flex-basis: 100% !important;
        flex-grow: 2 !important;
      }
    }
    @media only screen and (max-width: 481px) {
      th {
        &:first-child {
          min-width: 90px !important;
        }
        &.eq-width-2 {
          min-width: 45.5px !important;
          width: 45.5px !important;
        }
        &:last-child {
          width: 80px !important;
        }
      }
      td {
        button {
          margin: auto 0 !important;
        }
        display: block !important;
        &:first-child {
          min-width: 100px !important;
          width: 100px !important;
        }
        &.eq-width-2 {
          min-width: 55px !important;
          width: 55px !important;
        }
        &:last-child {
          width: 89px !important;
        }
      }
    }
  }
}

table {
  tr {
    &.gray {
      background-color: $gray_lighter !important;
    }
  }
}

.center {
  text-align: center !important;
  justify-content: center;
}

.space-evenly {
  justify-content: space-evenly !important;
}

.space-between {
  justify-content: space-between !important;
}

.space-around {
  justify-content: space-around !important;
}

.no-padding {
  padding: 0 !important;
}

.middle {
  align-items: center !important;
}

.bg-white {
  background: $white;
}

.no-margin {
  margin: 0 !important;
}

.heading {
  font-weight: 400 !important;
}

.align {
  &-center {
    align-items: center !important;
  }
  &-start {
    align-items: flex-start !important;
  }
  &-end {
    align-items: flex-end !important;
  }
}

.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &-row {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    &.evenly {
      justify-content: space-evenly !important;
    }
    &.between {
      justify-content: space-between !important;
    }
    &.around {
      justify-content: space-around !important;
    }
    &.middle {
      justify-content: center !important;
    }
  }
  &-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap !important;
    align-items: flex-start;
  }
  &.middle {
    align-items: center;
  }
  .end {
    justify-content: end;
  }
  .wrap {
    flex-wrap: wrap !important;
  }
  .nowrap {
    flex-wrap: nowrap !important;
  }
}

.margin-0-half {
  margin: 0.5em;
}

.margin-1 {
  margin: 1em;
}

.margin-1-half {
  margin: 1.5em;
}

.margin-top-0-half {
  margin-top: 0.5em !important;
}

.margin-top-1 {
  margin-top: 1em !important;
}

.margin-top-1-half {
  margin-top: 1.5em !important;
}

.margin-right-0-half {
  margin-right: 0.5em !important;
}

.margin-right-1 {
  margin-right: 1em !important;
}

.margin-right-1-half {
  margin-right: 1.5em !important;
}

.margin-left-0-half {
  margin-left: 0.5em !important;
}

.margin-left-1 {
  margin-left: 1em !important;
}

.margin-left-1-half {
  margin-left: 1.5em !important;
}

.margin-bottom-0-half {
  margin-bottom: 0.5em !important;
}

.margin-bottom-0 {
  margin-bottom: 0em !important;
}

.margin-bottom-1 {
  margin-bottom: 1em !important;
}

.margin-bottom-1-half {
  margin-bottom: 1.5em !important;
}

.padding-0-half {
  padding: 0.5em;
}

.padding-1 {
  padding: 1em !important;
}

.padding-1-half {
  padding: 1.5em;
}

.padding-top-0-half {
  padding-top: 0.5em !important;
}

.padding-top-1 {
  padding-top: 1em !important;
}

.padding-top-1-half {
  padding-top: 1.5em !important;
}

.padding-right-0-half {
  padding-right: 0.5em !important;
}

.padding-right-1 {
  padding-right: 1em !important;
}

.padding-right-1-half {
  padding-right: 1.5em !important;
}

.padding-left-0-half {
  padding-left: 0.5em !important;
}

.padding-left-1 {
  padding-left: 1em !important;
}

.padding-left-1-half {
  padding-left: 1.5em !important;
}

.padding-bottom-0-half {
  padding-bottom: 0.5em !important;
}

.padding-bottom-1 {
  padding-bottom: 1em !important;
}

.padding-bottom-1-half {
  padding-bottom: 1.5em !important;
}

.pointer {
  cursor: pointer;
}

.no-margin-top {
  margin-top: 0 !important;
}

.margin-top-0-half {
  margin-top: 0.5em !important;
}

.margin-1 {
  margin: 1em !important;
}

.bg {
  &-secondary {
    background-color: $main_secondary_colour !important;
  }
  &-primary {
    background-color: $main_primary_colour !important;
  }
}

.text {
  &-primary {
    color: $platform_primary_colour !important;
    color: $main_primary_colour !important;
  }
  &-secondary {
    color: $main_secondary_colour !important;
  }
}

.pointer {
  cursor: pointer;
}

.border {
  border: $borders;
  &-gray {
    border: $borders;
  }
}

.scroll {
  overflow: scroll !important;
  -ms-overflow-style: scrollbar; /* Internet Explorer 10+ */
  scrollbar-width: thin; /* Firefox */
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray_lighter;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $gray_lighter;
  }
  &-x {
    -ms-overflow-style: scrollbar; /* Internet Explorer 10+ */
    scrollbar-width: thin; /* Firefox */
    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray_lighter;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: $gray_lighter;
    }
    overflow-x: scroll !important;
  }
  &-y {
    -ms-overflow-style: scrollbar; /* Internet Explorer 10+ */
    scrollbar-width: thin; /* Firefox */
    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray_lighter;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: $gray_lighter;
    }
    overflow-y: scroll !important;
  }
}

.no-scrollbar {
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
  scrollbar-width: none !important; /* Firefox */
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
}

.col-12 {
  width: 100% !important;
}

.col-11 {
  width: 91.66666667% !important;
}

.col-10 {
  width: 83.33333333% !important;
}

.col-9 {
  width: 75% !important;
}

.col-8 {
  width: 66.66666667% !important;
}

.col-7 {
  width: 58.33333333% !important;
}

.col-6 {
  width: 50% !important;
}

.col-5 {
  width: 41.66666667% !important;
}

.col-4 {
  width: 33.33333333% !important;
}

.col-3 {
  width: 25% !important;
}

.col-2 {
  width: 16.66666667% !important;
}

.col-1 {
  width: 8.33333333% !important;
}

@media (min-width: 1024px) {
  .col-md-6 {
    width: 50% !important;
  }
}

@media (max-width: 1024px) {
  .col-md-6 {
    width: 100%;
  }
}

@for $i from 1 through 100 {
  .z-#{$i} {
    z-index: $i;
    position: relative;
    background: $white;
  }
}

$button-color: $white;
$button-hover-color: $gray_lighter;
$animation-duration: 0.3s;

.special-list-item {
  width: 100%;
  cursor: pointer;
  color: $default_text_color;
  position: relative;
  font-size: 18px;
  border: none;
  background-color: $button-color;
  overflow: hidden;
  z-index: 1;
  border: 0.5px solid #fff;
  transition:
    background-color $animation-duration,
    width $animation-duration;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: $button-hover-color;
    transition: width $animation-duration;
  }

  &:hover {
    background-color: $button-hover-color;

    &::before {
      width: 100%;
    }

    animation: $animation-duration ease-in-out forwards;
  }

  > span {
    z-index: 2;
    position: relative;
  }

  // &.collection {
  //   background-color: $main_primary_colour;
  // }
  // &.entity {
  //   background-color: $main_secondary_colour;
  // }
  // &.indicator {
  //   background-color: $teriary_colour;
  // }
}

$switch_size: 40px;

.toggle-switch {
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: $switch_size;
    height: calc(#{$switch_size} / 2);
    background: grey;
    display: block;
    border-radius: calc(#{$switch_size} / 2);
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(#{$switch_size} * 0.45);
    height: calc(#{$switch_size} * 0.45);
    border-radius: calc(#{$switch_size} * 0.45);
    transition: 0.4s;
  }

  input:checked + label {
    background: #bada55;
  }

  input:checked + label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: calc(#{$switch_size} * 0.65);
  }
}

.border-circle {
  border-radius: 50% !important;
}

.w-100 {
  width: 100%;
}

.w-200 {
  width: 200px !important;
}

.t-white {
  color: $white !important;
  &:hover,
  &:focus {
    color: $white !important;
  }
  span,
  p {
    color: $white !important;
    &:hover,
    &:focus {
      color: $white !important;
    }
  }
  &:disabled {
    color: $gray_dark !important;
    &:hover,
    &:focus {
      color: $gray_dark !important;
    }
    span,
    p {
      color: $gray_dark !important;
      &:hover,
      &:focus {
        color: $gray_dark !important;
      }
    }
  }
}

.default-text {
  color: $default_text_color;
  * {
    color: $default_text_color !important;
  }
  &:hover,
  &:focus,
  &:active {
    color: $default_text_color;
    * {
      color: $default_text_color !important;
    }
  }
}

.scale-2 {
  scale: 2 !important;
}

.icon {
  width: 30px !important;
  height: 30px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  > img {
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
  }
  &.circle {
    border-radius: 50% !important;
  }
  &.t {
    &-large {
      width: 40px !important;
      height: 40px !important;
      > img {
        width: 40px;
        height: 40px;
      }
    }
    &-small {
      width: 20px !important;
      height: 20px !important;
      > img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.padding {
  padding: $block_padding !important;
}

.margin {
  margin: $block_padding !important;
}

.draggable {
  cursor: grab;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.no-image-placeholder {
  width: 100%;
  height: 100%;
  background: $gray_lighter;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray_dark;
  background: $gray_lighter;
}

.skeleton-loader {
  width: 100%;
  height: 100%;
  animation: moveBackgroundGradient 2s ease-in-out infinite;
  background: linear-gradient(90deg, $gray_dark, $gray_lighter, $gray_dark);
  background-size: 200% 100%;
  z-index: 1;
}

@keyframes moveBackgroundGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

span,
p {
  &.disabled {
    color: $gray_dark !important;
  }
}

.text {
  &-large {
    font-size: 1.5rem !important;
  }
  &-small {
    font-size: 0.8rem !important;
  }
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.no-scroll {
  overflow: hidden !important;
}

.no-border {
  border: none !important;
  > * {
    border: none !important;
  }
}
