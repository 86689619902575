@import '_variables.scss';

.marketing-site {
  $primary_background_colour: #f1f1f1;
  $secondary_background_colour: $main_secondary_colour;
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  a,
  button {
    cursor: pointer !important;
  }

  /* all similar content styling codes */
  section {
    padding: 100px 0;
  }
  .max-width {
    max-width: 1300px;
    padding: 0 80px;
    margin: auto;
  }
  .about,
  .services,
  .skills,
  .teams,
  .contact,
  footer {
    font-family: 'Poppins', sans-serif;
  }
  .about .about-content,
  .services .serv-content,
  .skills .skills-content,
  .contact .contact-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  section .title {
    position: relative;
    text-align: center;
    font-size: 40px !important;
    font-weight: 500;
    margin-bottom: 60px;
    padding-bottom: 20px;
    font-family: 'Ubuntu', sans-serif;
  }
  section .title::before {
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 180px;
    height: 3px;
    background: $secondary_background_colour;
    transform: translateX(-50%);
  }
  section .title::after {
    position: absolute;
    bottom: -8px;
    left: 50%;
    font-size: 20px;
    color: $main_primary_colour;
    padding: 0 5px;
    background: $primary_background_colour;
    transform: translateX(-50%);
  }

  /* navbar-marketing styling */
  .navbar-marketing {
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 30px 0;
    font-family: 'Ubuntu', sans-serif;
    transition: all 0.3s ease;
    background: $background_default;
  }
  .navbar-marketing.sticky {
    padding: 15px 0;
    background: $secondary_background_colour;
  }
  .navbar-marketing .max-width {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-marketing .logo .hire {
    color: #fff;
    font-size: 35px;
    font-weight: 600;
    cursor: pointer;
  }
  .navbar-marketing .logo .hire span {
    color: $main_primary_colour;
    transition: all 0.3s ease;
  }
  .navbar-marketing.sticky .logo .hire span {
    color: #fff;
  }
  .navbar-marketing .menu li {
    list-style: none;
    display: inline-block;
  }
  .navbar-marketing .menu li .hire {
    display: block;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-left: 25px;
    transition: color 0.3s ease;
  }
  .navbar-marketing .menu li .hire:hover {
    color: $main_primary_colour;
  }
  .navbar-marketing.sticky .menu li a:hover {
    color: #fff;
  }

  /* menu btn styling */
  .menu-btn {
    color: #fff;
    font-size: 23px;
    cursor: pointer;
    display: none;
  }
  .scroll-up-btn {
    position: fixed;
    height: 45px;
    width: 42px;
    background: $main_primary_colour;
    right: 30px;
    bottom: 10px;
    text-align: center;
    line-height: 45px;
    color: #fff;
    z-index: 9999;
    font-size: 30px;
    border-radius: 6px;
    border-bottom-width: 2px;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
  }
  .scroll-up-btn.show {
    bottom: 30px;
    opacity: 1;
    pointer-events: auto;
  }
  .scroll-up-btn:hover {
    filter: brightness(90%);
  }

  /* home section styling */
  .home {
    display: flex;
    background-color: $secondary_background_colour;
    height: 100vh;
    color: #fff;
    min-height: 500px;
    background-size: cover;
    background-attachment: fixed;
    font-family: 'Ubuntu', sans-serif;
  }
  .home .max-width {
    margin: auto 0 auto 30px;
  }
  .home .home-content .text-1 {
    font-size: 27px;
  }
  .home .home-content .text-2 {
    font-size: 75px;
    font-weight: 600;
    margin-left: -3px;
  }
  .home .home-content .text-3 {
    font-size: 40px;
    margin: 5px 0;
  }
  .home .home-content .text-3 span {
    color: $main_primary_colour;
    font-weight: 500;
  }
  .home .home-content .hire {
    display: inline-block;
    background: $main_primary_colour;
    color: #fff;
    font-size: 25px;
    padding: 12px 36px;
    margin-top: 20px;
    font-weight: 400;
    border-radius: 6px;
    border: 2px solid $main_primary_colour;
    transition: all 0.3s ease;
  }
  .home .home-content .hire:hover {
    color: $background_default;
    background: none;
  }

  /* about section styling */
  .about .title::after {
  }
  .about .about-content .left {
    width: 45%;
  }
  .about .about-content .left img {
    height: 400px;
    width: 400px;
    object-fit: cover;
    border-radius: 6px;
  }
  .about .about-content .right {
    width: 55%;
  }
  .about .about-content .right .text {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .about .about-content .right .text span {
    color: $main_primary_colour;
  }
  .about .about-content .right p {
    text-align: justify;
  }
  .about .about-content .right a {
    display: inline-block;
    background: $main_primary_colour;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 30px;
    margin-top: 20px;
    border-radius: 6px;
    border: 2px solid $main_primary_colour;
    transition: all 0.3s ease;
  }
  .about .about-content .right a:hover {
    color: $main_primary_colour;
    background: none;
  }

  /* services section styling */
  .services,
  .teams {
    color: #fff;
    background: $secondary_background_colour;
  }
  .services .title::before,
  .teams .title::before {
    color: red;
    background: $primary_background_colour;
  }
  .services .title::after,
  .teams .title::after {
    background: $secondary_background_colour;
  }
  .services .serv-content .card {
    background: $primary_background_colour;
    text-align: center;
    border-radius: 6px;
    padding: 20px 25px;
    transition: all 0.3s ease;
  }
  .services .serv-content .card:hover {
    background: $main_primary_colour;
  }
  .services .serv-content .card .box {
    transition: all 0.6s ease;
  }
  .services .serv-content .card:hover * {
    color: $background_default;
  }
  .services .serv-content .card i {
    font-size: 50px;
    color: $main_primary_colour;
    transition: color 0.6s ease;
  }
  .services .serv-content .card:hover i {
    color: $background_default;
  }
  .services .serv-content .card .text {
    font-size: 25px;
    font-weight: 500;
    margin: 10px 0 7px 0;
  }

  /* skills section styling */

  .skills .title::after {
  }
  .skills .skills-content .column {
    width: calc(50% - 30px);
  }
  .skills .skills-content .left .text {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .skills .skills-content .left p {
    text-align: justify;
  }
  .skills .skills-content .left a {
    display: inline-block;
    background: $main_primary_colour;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 16px;
    margin-top: 20px;
    border-radius: 6px;
    border: 2px solid $main_primary_colour;
    transition: all 0.3s ease;
  }
  .skills .skills-content .left a:hover {
    color: $main_primary_colour;
    background: none;
  }
  .skills .skills-content .right .bars {
    margin-bottom: 15px;
  }
  .skills .skills-content .right .info {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
  }
  .skills .skills-content .right span {
    font-weight: 500;
    font-size: 18px;
  }
  .skills .skills-content .right .line {
    height: 5px;
    width: 100%;
    background: lightgrey;
    position: relative;
  }
  .skills .skills-content .right .line::before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background: $main_primary_colour;
  }
  .skills-content .right .html::before {
    width: 100%;
  }

  /* teams section styling */
  .teams .title::after {
  }
  .teams .carousel .card {
    background: $primary_background_colour;
    border-radius: 6px;
    padding: 25px 35px;
    text-align: center;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .teams .carousel .card:hover {
    background: $main_primary_colour;
  }
  .teams .carousel .card .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  .teams .carousel .card:hover .box {
    transform: scale(1.05);
  }
  .teams .carousel .card .text {
    font-size: 25px;
    font-weight: 500;
    margin: 10px 0 7px 0;
  }
  .teams .carousel .card img {
    height: 150px;
    width: 150px;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid $main_primary_colour;
    transition: all 0.3s ease;
  }
  .teams .carousel .card:hover img {
    border-color: #fff;
  }
  .owl-theme {
    .item {
      .card {
        .flex {
          width: 100%;
          justify-content: space-between;
          img {
            width: 50%;
            margin: 0;
          }
        }
      }
    }
  }

  .owl-dots {
    text-align: center;
    margin-top: 20px;
  }
  .owl-dot {
    height: 13px;
    width: 13px;
    margin: 0 5px;
    outline: none !important;
    border-radius: 50%;
    border: 2px solid $main_primary_colour !important;
    transition: all 0.3s ease;
  }
  .owl-dot.active {
    width: 35px;
    border-radius: 14px;
  }
  .owl-dot.active,
  .owl-dot:hover {
    background: $main_primary_colour !important;
  }

  /* contact section styling */
  .contact .title::after {
  }
  .contact .contact-content .column {
    width: calc(50% - 30px);
  }
  .contact .contact-content .text {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .contact .contact-content .left p {
    text-align: justify;
  }
  .contact .contact-content .left .icons {
    margin: 10px 0;
  }
  .contact .contact-content .row {
    display: flex;
    height: 65px;
    align-items: center;
  }
  .contact .contact-content .row .info {
    margin-left: 10px;
    width: auto !important;
  }
  .contact .contact-content .row i {
    font-size: 25px;
    color: $main_primary_colour;
    width: auto !important;
  }
  .contact .contact-content .info .head {
    font-weight: 500;
  }
  .contact .contact-content .info .sub-title {
    color: #333;
  }
  .contact .right form .fields {
    display: flex;
  }
  .contact .right form .field,
  .contact .right form .fields .field {
    height: 45px;
    width: 100%;
    margin-bottom: 15px;
  }
  .contact .right form .textarea {
    height: 80px;
    width: 100%;
  }
  .contact .right form .name {
    margin-right: 10px;
  }
  .contact .right form .email {
    margin-left: 10px;
  }
  .contact .right form .field input,
  .contact .right form .textarea textarea {
    height: 100%;
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 6px;
    outline: none;
    padding: 0 15px;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    transition: all 0.3s ease;
  }
  .contact .right form .field input:focus,
  .contact .right form .textarea textarea:focus {
    border-color: #b3b3b3;
  }
  .contact .right form .textarea textarea {
    padding-top: 10px;
    resize: none;
  }
  .contact .right form .button {
    height: 47px;
    width: 170px;
  }
  .contact .right form .button button {
    width: 100%;
    height: 100%;
    border: 2px solid $main_primary_colour;
    background: $main_primary_colour;
    color: #fff;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .contact .right form .button button:hover {
    color: $main_primary_colour;
    background: none;
  }

  /* footer section styling */
  footer {
    background: $secondary_background_colour;
    padding: 15px 23px;
    color: #fff;
    text-align: center;
  }
  footer span a {
    color: $main_primary_colour;
    text-decoration: none;
  }
  footer span a:hover {
    text-decoration: underline;
  }

  /* responsive media query start */
  @media (max-width: 1300px) {
    .home .max-width {
      margin-left: 0px;
    }
  }

  @media (max-width: 1104px) {
    .about .about-content .left img {
      height: 350px;
      width: 350px;
    }
  }

  @media (max-width: 991px) {
    .max-width {
      padding: 0 50px;
    }
  }
  @media (max-width: 947px) {
    .menu-btn {
      display: block;
      z-index: 999;
    }
    .menu-btn i.active:before {
    }
    .navbar-marketing .menu {
      position: fixed;
      height: 100vh;
      width: 100%;
      left: -100%;
      top: 0;
      background: $secondary_background_colour;
      text-align: center;
      padding-top: 80px;
      transition: all 0.3s ease;
    }
    .navbar-marketing .menu.active {
      left: 0;
    }
    .navbar-marketing .menu li {
      display: block;
    }
    .navbar-marketing .menu li a {
      display: inline-block;
      margin: 20px 0;
      font-size: 25px;
    }
    .home .home-content .text-2 {
      font-size: 70px;
    }
    .home .home-content .text-3 {
      font-size: 35px;
    }
    .home .home-content a {
      font-size: 23px;
      padding: 10px 30px;
    }
    .max-width {
      max-width: 930px;
    }
    .about .about-content .column {
      width: 100%;
    }
    .about .about-content .left {
      display: flex;
      justify-content: center;
      margin: 0 auto 60px;
    }
    .about .about-content .right {
      flex: 100%;
    }
    .services .serv-content .card {
      width: calc(50% - 10px);
      margin-bottom: 20px;
    }
    .skills .skills-content .column,
    .contact .contact-content .column {
      width: 100%;
      margin-bottom: 35px;
    }
  }

  @media (max-width: 690px) {
    .max-width {
      padding: 0 23px;
    }
    .home .home-content .text-2 {
      font-size: 60px;
    }
    .home .home-content .text-3 {
      font-size: 32px;
    }
    .home .home-content a {
      font-size: 20px;
    }
    .services .serv-content .card {
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    .home .home-content .text-2 {
      font-size: 50px;
    }
    .home .home-content .text-3 {
      font-size: 27px;
    }
    .about .about-content .right .text,
    .skills .skills-content .left .text {
      font-size: 19px;
    }
    .contact .right form .fields {
      flex-direction: column;
    }
    .contact .right form .name,
    .contact .right form .email {
      margin: 0;
    }
    .scroll-up-btn {
      right: 15px;
      bottom: 15px;
      height: 38px;
      width: 35px;
      font-size: 23px;
      line-height: 38px;
    }
  }
}
