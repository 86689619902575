@import '_variables.scss';

/////////////////////////////////////////////////////////////////////////////////////////////////////////
///
///                                          T I C K S    S T A R T
///
/////////////////////////////////////////////////////////////////////////////////////////////////////////

.ticks-entry {
  align-items: center;
  padding: 0.25em 0;
  .ticks-controls {
    height: 35px;
    overflow: hidden;
    vertical-align: middle;
    margin: 0 !important;
    position: sticky;
    left: 0;
    z-index: 4;
    background: $background_default;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0.25em !important;
    font-size: 1em;
    text-align: left;
    &.width {
      @for $i from 1 through 8 {
        &-#{$i} {
          $mapped_cell_width: 120px - ($i * 5px);
          $large_row_dif: 80px;
          $small_cell_width: 60px;
          width: fit-content;
          > span {
            width: $mapped_cell_width;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: $mapped_cell_width;
            justify-content: flex-start;
            padding-top: 0.5em;
            &.large {
              width: $mapped_cell_width + $large_row_dif;
              max-width: $mapped_cell_width + $large_row_dif;
              justify-content: flex-start;
            }
            // &.full {
            //   width: 600px;
            //   max-width: 600px;
            //   justify-content: flex-start;
            // }
            &.small {
              width: $small_cell_width;
              max-width: $small_cell_width;
              font-size: 1em !important;
            }
          }
        }
      }
    }

    > span {
      align-items: center;
      display: flex;
      width: 100px;
      overflow: hidden;

      &.settings {
        position: absolute;
        right: 0px;
        width: 30px;
      }
    }
    &.value {
      height: 23px;
      font-size: 0.7em;
      align-items: center;
    }
    &.datesMini {
      height: 23px;
      font-size: 0.7em;
      align-items: center;
      margin-left: 3.5em !important;
    }
    &.individualMode {
      width: 350px;
    }
    // add a media query for screen less than 1024px
    // @media (max-width: 1024px) {
    //   width: 270px;
    //   span,
    //   p,
    //   button {
    //     font-size: 0.7em;
    //   }
    // }
    // add a media query for screen less than 768px
    @media (max-width: 450px) {
      position: relative !important;
    }
  }
  .ticks {
    min-width: 80%;
    max-width: 80%;
    display: flex;
    // > .sticky-ticks {
    //   position: sticky;
    //   right: 0;
    //   z-index: 3;
    //   background: $white;
    //   outline: $gray solid 0.5px;
    //   display: flex;
    //   justify-content: space-between;
    // }
    span {
      align-items: center;
      padding-left: 1em;
      display: flex;
    }
  }
}

.tick-wrapper {
  position: relative;
  width: fit-content;
  background-color: transparent;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: row;
  align-items: center;
}

button > img.icon {
  width: 10px;
}

.tick {
  width: $cell_width;
  min-width: $cell_width;
  height: 35px;
  border: $borders;
  text-align: center;
  vertical-align: middle;
  overflow: visible;
  border-right: 0;
  border-bottom: 0;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.difference {
      font-size: 95%;
      &.small {
        font-size: 70%;
      }
    }
  }
  img {
    width: 17px;
  }

  &.zone {
    &-1 {
      background-color: var(--zone-1);
    }
    &-2 {
      background-color: var(--zone-2);
    }
    &-3 {
      background-color: var(--zone-3);
    }
    &-4 {
      background-color: var(--zone-4);
    }
    &-5 {
      background-color: var(--zone-5);
    }
    &-6 {
      background-color: var(--zone-6);
    }
  }
}

.data-display-checkboxes {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  > div {
    margin: 0.5em;
  }
  &.half {
    width: 50%;
    margin: 0;
    > div {
      margin: 0.5em;
    }
  }
  &.quarter {
    width: 25%;
    margin: 0;
    > div {
      margin: 0.5em;
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideInX {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideInXPartial {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

.banner-strip {
  font-weight: bold;
  font-size: 1.2em;
  padding: 0em 0.5em;
  width: 100%;
  margin: 0.5em auto;
  border-left: 4px solid;

  &.danger {
    border-color: $red_darker;
    background-color: $red_light;
  }

  &.warning {
    border-color: $yellow_darker;
    background-color: $yellow_lighter;
  }

  &.info {
    border-color: $blue_darker;
    background-color: $blue_light;
  }

  &.action {
    border-color: $main_primary_colour;
    background-color: $main_primary_colour_light;
    color: $background_default_new;
  }

  &.success {
    border-color: $green_darker;
    background-color: $green_light;
  }
  &.left {
    text-align: left;
    margin-left: 0;
  }
  &.inline {
    font-size: 0.7rem;
    padding: 0em 0.25em;
    border-left: 2px solid;
    font-weight: normal;
    text-decoration: none !important;
  }
}

.toggled-collapsible-block {
  font-weight: bold;
  padding: 0em 0.5em;
  width: 100%;
  border-left: 2px solid;
  border-color: $green_light;

  &.danger {
    border-color: $red_light;
  }

  &.warning {
    border-color: $yellow_light;
  }

  &.info {
    border-color: $blue_light;
  }

  &.action {
    border-color: $main_primary_colour;
  }

  &.left {
    text-align: left;
    margin-left: 0;
  }

  &.inline {
    font-size: 0.7rem;
    padding: 0em 0.25em;
    border-left: 2px solid;
    font-weight: normal;
    text-decoration: none !important;
  }

  &.t-small {
    font-size: 0.8rem;
  }

  &.t-small-children {
    *:not(.block-title):not(.block-title *) {
      font-size: 0.8rem !important;
      input,
      select,
      .toggle-container {
        font-size: 0.8rem !important;
        height: 20px !important;
      }
    }
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////
///
///                                          T I C K S    E N D
///
/////////////////////////////////////////////////////////////////////////////////////////////////////////

/// /////////////////////////////////////////////////////////////////////////////////////////////////////
///
///                                    C O M P O N E N T S    S T A R T
///
/////////////////////////////////////////////////////////////////////////////////////////////////////////

.maintanance {
  text-align: center;
  padding: 150px;
  h1 {
    font-size: 50px;
  }
  font:
    20px Helvetica,
    sans-serif;
  color: #333;
  memo {
    display: block;
    text-align: left;
    width: 650px;
    margin: 0 auto;
  }
  a {
    color: #dc8100;
    text-decoration: none;
  }
  a:hover {
    color: #333;
    text-decoration: none;
  }
}

.slider {
  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus {
      outline: none;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
    }
    &::-moz-range-thumb {
      border: none;
    }
  }

  &-wrappers-ticks {
    padding: 5px;
    background: $gray;
    border-radius: 7px;
    box-shadow:
      -2px -2px 8px white,
      2px 2px 8px rgba(black, 0.5);

    @mixin track-style {
      display: flex;
      align-items: center;
      height: 10px;
      border-radius: 10px;
      box-shadow:
        inset -2px -2px 8px white,
        inset 2px 2px 8px rgba(black, 0.5);
    }

    @mixin thumb-style {
      position: relative;
      top: -50%;
      width: 20px;
      height: 20px;
      background-color: $gray;
      background-image: linear-gradient(-45deg, rgba(white, 0.8), transparent);
      border-radius: 50%;
      box-shadow:
        -1px -1px 2px white,
        1px 1px 2px rgba(black, 0.3);
    }
    &::-webkit-slider-runnable-track {
      @include track-style;
    }
    &::-webkit-slider-thumb {
      @include thumb-style;
    }
    &::-moz-range-track {
      @include track-style;
    }
    &::-moz-range-thumb {
      @include thumb-style;
    }
  }
}

$panel_width_available: 100vw;

.popup-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  z-index: 998;
  pointer-events: auto;

  &.nested {
    height: auto;
    width: auto;
  }

  .entity-blocks {
    top: 0 !important;
    height: 100% !important;
  }

  &.full-height {
    .panel-content {
      height: 100% !important;
    }

    .panel-container {
      height: auto !important;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .panel-container {
    overflow-y: scroll;
    position: relative;
    background-color: $background_default;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    pointer-events: auto;
    transform: scale(0);
    transition: transform 3s ease-out;
    opacity: 0;
    z-index: 1000;
    overflow-x: hidden;
    &.small {
      width: calc(#{$panel_width_available} / 2.7);
      height: 35vh;
    }

    &.medium {
      width: calc(#{$panel_width_available} / 2);
      height: 45vh;
    }

    &.large {
      width: calc(#{$panel_width_available} / 1.5);
      height: 80vh;
    }

    &.largest {
      width: calc(#{$panel_width_available} / 1.1);
      height: 95vh;
    }

    &.fit {
      height: auto !important;
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .submit-buttons {
      position: fixed;
      bottom: 10px;
      transform: translate(-50%, 0);
      left: 50%;
    }
  }

  .tabbing-switch {
    button {
      width: fit-content !important;
    }
  }

  &.open {
    display: flex;
    opacity: 1;

    .panel-container {
      transform: scale(1);
      opacity: 1;
    }
  }

  .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $borders;
    height: 80px;
    overflow: hidden;
    padding: 0.25rem 1rem 0 1rem;
  }

  .panel-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0.25em;
    padding: 1em 1em 4em 1em;
    overflow: hidden;
    // height: 80%;
    max-height: 90%;
    max-width: 100%;
    overflow-y: scroll;
    width: 100%;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  &.inner {
    $panel_width_available: calc(100vw - #{$side_panel_width});

    left: $side_panel_width;
    width: $panel_width_available;

    .panel-container {
      max-height: 90vh;
      &.small {
        width: calc(#{$panel_width_available} / 2.7);
        height: 35vh;
      }
      &.medium {
        width: calc(#{$panel_width_available} / 2);
        height: 45vh;
      }
      &.large {
        width: calc(#{$panel_width_available} / 1.2);
        height: 80vh;
      }
      &.largest {
        width: calc(#{$panel_width_available});
        height: 95vh;
      }
      &.fit {
        height: auto !important;
      }
    }
  }
}

.access-denied {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1.5rem;
  }
}

/// /////////////////////////////////////////////////////////////////////////////////////////////////////
///
///                                    C O M P O N E N T S    E N D
///
/////////////////////////////////////////////////////////////////////////////////////////////////////////

/// /////////////////////////////////////////////////////////////////////////////////////////////////////
///
///                                 A N I M A T I O N S    S T A R T
///
/////////////////////////////////////////////////////////////////////////////////////////////////////////

.loader {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  &.fullscreen {
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  &.sidepanel {
    position: absolute;
    left: 10%;
  }
  > h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    .dot {
      width: 10px;
      height: 10px;
      margin: 0 10px;
      border-radius: 50%;
      background-color: black;
      animation: dot-animation 3s infinite;
    }

    .dot:nth-child(1) {
      animation-delay: 0.2s;
    }

    .dot:nth-child(2) {
      animation-delay: 0.4s;
    }

    .dot:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes dot-animation {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0);
    }
  }

  .spinner {
    border: 10px solid #f3f3f3;
    border-top: 10px solid $teriary_colour;
    border-radius: 50%;
    // position at the center of the screen

    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    text-align: center;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.c-inline-spinner,
.c-inline-spinner:before {
  display: inline-block;
  width: 11px;
  height: 11px;
  transform-origin: 50%;
  border: 2px solid transparent;
  border-color: $main_secondary_colour $main_secondary_colour transparent
    transparent;
  border-radius: 50%;
  content: '';
  animation: linear spin 900ms infinite;
  position: relative;
  vertical-align: inherit;
  line-height: inherit;
}
.c-inline-spinner {
  top: 3px;
  margin: 0 3px;
  &:before {
    border-color: $main_secondary_colour $main_secondary_colour transparent
      transparent;
    position: absolute;
    left: -2px;
    top: -2px;
    border-style: solid;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////
///
///                                A N I M A T I O N S    E N D
///
/////////////////////////////////////////////////////////////////////////////////////////////////////////
///
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////
///
///                                N E W    M O D E L    S T A R T
///
/////////////////////////////////////////////////////////////////////////////////////////////////////////

.sticky-header {
  background: $background_default_new;
  height: $sticky_header_height;
  display: flex;
  align-items: center;
  &.primary-workspace {
    background: $background_default_new;
  }
  &.secondary-workspace {
    background: $gray_darker;
    path[fill='#525863'] {
      fill: $white !important;
    }
  }
}

.new-modal {
  width: $new_model_width;
  // background: $background_default_new;
  height: 100vh;
  position: fixed;
  top: 0;
  top: 0;
  right: 0;
  min-width: 70vw !important;
  // overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .entity {
    height: $entity_height;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: scroll;
    animation: fadein 0.5s ease-in-out;
    width: 100%;
    align-items: flex-start !important;
    > * {
      margin: 0 !important;
    }
    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .entity-header {
      padding: $block_padding;
      padding-top: 0;
      background: $background_default_new !important;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: column;
      // align-items: flex-end;
      height: $entity_header_height;
      border-top: $gray_light 1px solid !important;
      border-bottom: $gray_light 1px solid !important;

      overflow: hidden;
      position: fixed;
      // z-index: 30;
      width: $new_model_width;

      $profile_width: 80px;
      $text_margin: calc(100% - $profile_width);

      .owner {
        position: absolute;
        left: 0;
        display: flex;
        flex-direction: column;
        width: $profile_width !important;
        align-items: center;
        padding: 5px;
        .owner-image {
          width: 65px !important;
          height: 65px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: $borders;
          background-color: $gray_light;
          position: relative;
          img,
          svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .owner-info {
          font-size: 10px;
        }
      }

      .title {
        margin: 0;
        font-size: 40px;
        line-height: 44px;
        font-weight: 900;
        color: $default_text_color;
        width: $text_margin;
        align-self: flex-end;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 768px) {
          font-size: 15px;
          width: 100%;
          max-width: 28ch;
        }
        @media (max-width: 1280px) {
          font-size: 30px !important;
          line-height: 34px !important;
        }
      }
      input.title {
        height: 64px !important;
        border: none !important;
      }
      .subtitle {
        font-weight: 900;
        margin: 0;
        position: relative;
        font-size: 12px;
        color: $default_text_color;
        text-align: left;
        width: 100%;
      }
      .description {
        width: $text_margin;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 17px;

        @media (max-width: 1280px) {
          font-size: 12px !important;
          line-height: 15px !important;
        }
      }
      &-details {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: $text_margin;
        align-self: flex-end;
        // :first-child {
        //   width: 100px;
        //   text-wrap: wrap;
        // }
        .chevrons {
          align-items: center;
          justify-content: flex-start;
          // transform: scale(0.9);
        }
      }
      .functional {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: $text_margin;
        align-self: flex-end;
      }

      + .entity-blocks {
        padding-top: 0;
        > .container.w-12:first-of-type,
        .container.w-12.first-of-type {
          padding: 0 !important;
          position: fixed;
          z-index: 30;
          width: $new_model_width !important;
          top: $entity_header_height_full;
          z-index: 20;
          height: $entity_header_parameters_height !important;
          right: 0 !important;
          > .entity-parameters {
            padding: 0 !important;
          }
        }
      }
    }
    .entity-info {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      overflow: auto;
      margin-bottom: $block_padding;
      margin-left: $block_padding;
      margin-right: calc($block_padding / 2);
      position: relative;
      border: $borders;

      > :n-child(2) {
        height: 25%;
        align-items: flex-start !important;
      }

      &-block {
        border: $white 1px solid;
        background: $background_default;
        overflow: scroll;
        height: 100%;
        input {
          margin: 0 0 0 5px !important;
          width: 100%;
        }
        h3,
        p {
          padding: 0.25em;
        }
      }
      .description {
        // height: 90%;
        padding: 5px !important;
        .entity-info-block {
          overflow: hidden;
          padding: 0.25em;
          textarea {
            padding: 0 5px !important;
            width: 100%;
            height: fit-content;
            max-height: 68%;
            min-height: 90px;
          }
          .functional-buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 1em;
            button {
              width: 50%;
            }
          }
          .indicators-search {
            max-height: 30vh !important;
            &-results {
              max-height: 100%;
            }
          }
        }
      }

      .tabbing-switch {
        position: sticky;
        top: 0;
        padding-left: calc(0.5em - 5px);
        > button {
          padding-left: 0 !important;
        }
      }
    }

    .entity-chart {
      // overflow-y: hidden;
      // overflow-x: hidden;
      // position: relative;
      // display: flex;
      // margin-bottom: $block_padding;
      // margin-right: $block_padding;
      // margin-left: calc($block_padding / 2);

      // border: $borders;
      // flex-direction: column;
      // align-items: center;
      // justify-content: flex-start;
      // height: 100%;
      // width: 50%;

      // > button {
      //   min-width: 40%;
      // }
      // .entity-parameters {
      //   position: sticky;
      //   top: 0;
      //   z-index: 20;
      // }
      // .google-chart {
      //   overflow: hidden;
      // }
    }
    .entity-data {
      // padding: $block_padding;
      position: relative;
      overflow: scroll;
      width: 100%;
      height: 100%;
      .data-mapped {
        margin: 0 auto;
        // border: $borders;
        overflow-x: scroll;
        height: auto;
        max-height: 80%;
        width: 100%;
        background: $background_default;

        &-head {
          position: sticky;
          z-index: 20;
        }
        // &-body {
        //   > .mapped-self-defined:first-of-type {
        //     > .ticks-entry {
        //       // padding-top: 3em !important;
        //       background: $third_column_color;
        //     }
        //   }

        //   .mapped-self-defined {
        //     .ticks-entry {
        //       background: $third_column_color;
        //       background-color: $third_column_color;
        //     }

        //     .ticks-controls {
        //       background: $third_column_color;
        //     }
        //   }
        // }

        button.update-db {
          position: sticky;
          background: $teriary_colour;
          border-radius: 0;
          &:hover,
          &:focus {
            background: $platform_secondary_colour;
            color: $white;
          }
        }
        .dates-wrapper {
          padding-top: 0 !important;
          > .tick-wrapper {
            display: flex;
            .ticks {
              .date-tick,
              .tick,
              td.value {
                min-width: $cell_width;
                width: $cell_width;
                border-left: $borders;
                background: $white;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                max-height: 23px !important;
                color: $default_text_color;
                & span {
                  // -ms-writing-mode: tb-rl;
                  // -webkit-writing-mode: vertical-rl;
                  // writing-mode: vertical-rl;
                  // transform: rotate(180deg);
                  white-space: nowrap;
                  font-size: 0.7em;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }

    .entity-parameters {
      display: flex;
      align-items: center;
      overflow-y: hidden;
      overflow-x: hidden;
      justify-content: space-between;
      background: $gray_lighter;
      height: 35px;
      width: 100%;
      transition: transform 0.3s ease-in-out;
      .css-187mznn-MuiSlider-root {
        padding: 0;
      }
    }
    .entity-block-1 {
      min-height: $entity_descriptions_and_chart_height;
      height: auto;
      max-height: $entity_descriptions_and_chart_height;
      display: flex;
      position: relative;
      top: calc($entity_header_height_full + 0.5%);
      width: 100%;
      &.full-height {
        height: 100%;
        max-height: calc(95vh - #{$entity_header_height_full});
        border-bottom: none;
        top: none;
      }
      .big-chart {
        margin: $block_padding;
        border: $borders;
        min-height: 52vh;
        overflow: hidden;
      }
    }
    .entity-block-2 {
      padding: $block_padding;
      margin: 0;
      // border: $borders;
      height: $entity_data_mapped_height;
      position: relative;
      width: 100%;
      top: calc($entity_header_height_full);
      overflow: visible;

      .entity-data {
        border: $borders;
        transition:
          transform 0.3s ease-in-out,
          opacity 0.3s ease-in-out,
          visibility 0.3s ease-in-out;
      }
      .entity-parameters {
        transition: transform 0.7s ease-in-out;
        width: 100%;
        z-index: 25; // Ensure it's always on top
      }
      &.collapsed {
        overflow: hidden;
        height: 0;
        .entity-data {
          overflow: hidden;
          transform: translateY(100%);
          opacity: 0;
          visibility: hidden;
        }
        .entity-parameters {
          position: fixed;
          bottom: 0;
          width: calc($new_model_width - 1em);
          transform: translateY(0%); // Start without translation
        }
      }
      &.expanded {
        .entity-data {
          transform: translateY(0%);
          opacity: 1;
          visibility: visible;
        }
        .entity-parameters {
          position: relative;
          bottom: auto;
          transform: translateY(0%); // Reset position
        }
      }
    }

    .entity-block-1 {
      min-height: $entity_descriptions_and_chart_height;
      height: auto;
      max-height: $entity_descriptions_and_chart_height;
      display: flex;
      position: relative;
      top: calc($entity_header_height_full + 0.5%);
      width: 100%;
      &.full-height {
        height: 100%;
        max-height: calc(95vh - #{$entity_header_height_full});
        border-bottom: none;
        top: none;
      }
      .config {
        margin: $block_padding;
        border: $borders;
      }
      .big-chart {
        margin: $block_padding;
        border: $borders;
        min-height: 52vh;
        overflow: hidden;
      }
    }

    &.indicator {
      .entity-blocks {
        justify-content: flex-end;
        > .container {
          overflow: visible !important;
        }
      }
    }

    &.trendline,
    &.forecast {
      .title {
        font-size: 40px;
        line-height: 44px;
        @media (max-width: 768px) {
          font-size: 15px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 28ch;
        }
      }
      .entity-parameters {
        overflow: visible !important;
        justify-content: flex-start;
      }
    }

    &.external {
      .title {
        font-size: 40px;
        line-height: 44px;
      }
      .entity-parameters {
        overflow: visible !important;
      }
    }

    &.forecast {
      .title * {
        font-size: 30px;
        line-height: 42px;
      }
    }

    // &.channel {
    //   .entity-header {
    //     flex-wrap: wrap;
    //     flex-direction: row;
    //     .buttons {
    //       top: 50%;
    //     }
    //   }
    // }

    .profile-image {
      width: 65px !important;
      height: 65px !important;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: $borders;
      background-color: $gray_light;
      position: relative;
      img,
      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .edit-text {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        text-align: center;
        width: 100%;
        padding: 0.25em;
        .file-upload {
          align-items: center !important;
          justify-content: center !important;
        }
      }
    }

    &.collection {
      .config {
        width: calc(100% - #{$block_padding}) !important;
        margin: calc($block_padding / 2);
        height: 100%;
      }
    }

    .container {
      padding: calc($block_padding / 2) !important;
      gap: 0 !important;
      position: relative;
      margin: 0 !important;
      max-height: 50% !important;
      max-width: none !important;

      // overflow-y: hidden;

      &.small {
        min-height: 29vh !important;
      }
      &.medium {
        min-height: 42vh !important;
      }
      &.large {
        min-height: 50vh !important;
        max-height: 100% !important;
      }
      &.tall {
        min-height: 56vh !important;
      }

      &.full {
        min-height: 81vh !important;
      }
      &.fit {
        height: fit-content !important;
      }
      &.auto {
        min-height: auto !important;
        max-height: fit-content !important;
      }
      > *:not(.loader):not(.entity-parameters),
      .entity-chart,
      .entity-info,
      .entity-data {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        border: $borders;
      }

      > .container {
        > *:not(.loader):not(.entity-parameters),
        .entity-chart,
        .entity-info,
        .entity-data {
          border: none !important;
        }

        .entity-data {
          overflow: visible !important;
          overflow-x: scroll !important;
        }
      }

      &.no-padding {
        padding: 0 !important;
      }

      &.no-border,
      &.no-borders {
        border: none !important;
        > * {
          border: none !important;
        }
      }

      &.borders {
        border: $borders !important;
      }

      &.padding {
        padding: $block_padding !important;
      }

      &.inline {
        > * {
          max-width: 50% !important;
        }
      }

      $width: calc(100% / 12);

      &.w {
        &-1 {
          width: calc($width) !important;
        }
        &-2 {
          width: calc($width * 2) !important;
        }
        &-3 {
          width: calc($width * 3) !important;
        }
        &-4 {
          width: calc($width * 4) !important;
        }
        &-5 {
          width: calc($width * 5) !important;
        }
        &-6 {
          width: calc($width * 6) !important;
        }
        &-7 {
          width: calc($width * 7) !important;
        }
        &-8 {
          width: calc($width * 8) !important;
        }
        &-9 {
          width: calc($width * 9) !important;
        }
        &-10 {
          width: calc($width * 10) !important;
        }
        &-11 {
          width: calc($width * 11) !important;
        }
        &-12 {
          width: calc($width * 12) !important;
        }
        @media (max-width: 768px) {
          &-1,
          &-2,
          &-3,
          &-4,
          &-5,
          &-6,
          &-7,
          &-8,
          &-9,
          &-10,
          &-11,
          &-12 {
            width: 100% !important;
            margin: 0 !important;
          }
        }
      }
    }

    .entity-blocks {
      display: flex;
      gap: 0;
      position: relative;
      padding: $block_padding;
      top: calc($entity_header_height_full + 0.5%);
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      align-content: flex-start;
      height: calc(100% - $entity_header_height_full - 0.5%);
      max-height: 100vh;
      overflow: scroll;
      // hide scrollbar
      &::-webkit-scrollbar {
        width: 0;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }

    .entity-chart {
      .entity-parameters {
        overflow: visible;
        > span {
          height: 35px;
          line-height: 35px;
        }
      }
    }

    .col-12 {
      > .entity-parameters {
        background: transparent;

        &.chart {
          background: $gray_lighter;
        }
      }
    }

    .entity-data {
      .ticks-entry {
        width: 100% !important;
        .tick-wrapper {
          display: flex;
          justify-content: space-between;
        }
        .ticks-controls {
          width: auto !important;
          max-width: fit-content !important;
          min-width: fit-content !important;
        }
        .ticks {
          width: auto !important;
          max-width: fit-content !important;
          min-width: fit-content !important;
          justify-content: flex-end;
        }
        .full {
          width: fit-content;
          max-width: fit-content;
        }
      }
    }
    &.homepage,
    &.site-search-page {
      /* This will remove scrollbars from the entire document in Webkit-based browsers */

      .entity-blocks {
        top: calc($entity_header_height_full + 0.5% - 40px);
      }
      * {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      }

      .entity-data {
        .data-mapped-head,
        .data-mapped-body {
          width: 100%;
          .tick-wrapper {
            width: 100% !important;
            position: relative;
          }
          .mapped {
            width: 100%;
            position: relative;
            .ticks-entry {
              position: relative;
            }

            .ticks-controls {
              width: 80% !important;
              position: relative;
            }
          }
          .ticks {
            position: absolute;
            right: 0;
            z-index: 29;
          }
        }
        .ticks-entry {
          .ticks-controls {
            width: 150px !important;
            max-width: 150px !important;
          }
          .small {
            width: 100px !important;
            max-width: 100px !important;
            padding: 0 0 0 0.25rem;
            display: flex;
            flex-direction: column;
            span {
              font-size: 0.7rem;
            }
          }
          .full {
            flex-direction: row;
            width: 150px !important;
            max-width: 150px !important;
            overflow: visible;
            text-overflow: clip;
            white-space: normal;
          }
        }
      }
    }
    &.calculated {
      .entity-block-2 {
        padding: 0 calc($block_padding * 1.5);
      }
    }
  }

  &.inner {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    min-width: 100% !important;
    max-width: 100% !important;
    position: relative;

    .entity-header {
      position: relative;
      top: 0;
      z-index: 30;
    }

    .entity-block-1 {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .entity-block-2 {
      .entity-parameters {
        width: auto;
      }
    }

    .entity-info,
    .entity-chart {
      height: 400px;
      width: 100%;
    }
  }
}

.data-select {
  padding: 1em;
  outline: 2px solid $gray;
  border-radius: 5px;
  margin: 2% 5%;
  &-all {
    text-align: center;
    font-size: larger;
  }
  &-checkboxes {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    > div {
      height: 30px;
      width: 50%;
    }
  }
}

#gridlines-parent {
  .datapoint-gridline {
    &.zone {
      &-1 {
        background-color: $red_darker;
      }
      &-2 {
        background-color: $red_light;
      }
      &-3 {
        background-color: $yellow_lighter;
      }
      &-4 {
        background-color: $green_light;
      }
      &-5 {
        background-color: $green_darker;
      }
    }
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////
///
///                                 N E W    M O D E L    E N D
///
/////////////////////////////////////////////////////////////////////////////////////////////////////////
///
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////
///
///                                 C H E C K B O X   S T A R T
///
/////////////////////////////////////////////////////////////////////////////////////////////////////////

.pretty {
  display: inline-block;
  line-height: 1;
  margin-right: 1em;
  position: relative;
  white-space: nowrap;
  input {
    cursor: pointer;
    height: 100%;
    left: 0;
    margin: 0;
    min-width: 1em;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}

.pretty-list {
  h1 {
    color: $white;
    background: $main_primary_colour;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 10px;
      font-size: 1rem;
      background: $gray_lighter;
      a {
        color: #333;
        text-decoration: underline;
      }

      &:last-child {
        border-bottom: none;
      }

      &.date {
        background: $background_default;
        font-size: larger;
        border-bottom: $borders;
        padding: 0.25em;
        margin: 0.25em;
      }
    }
  }
}

.panel-empty-page {
  text-align: center;
  font-size: 1.5em;
  width: 100%;
  min-width: 300px;
  height: 40%;
  min-height: 300px;
  z-index: 10;
}

.data-table {
  overflow: scroll;

  tr,
  th,
  td,
  div:not(.pretty):not(.pretty *) {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 35px !important;
  }

  th.options,
  td.options {
    min-width: 60px;
    max-width: 60px;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    cursor: pointer;
    z-index: 10;
    height: 35px !important;
    position: sticky;
    left: 0;
    background: $background_default;
    margin: 0;

    &:nth-of-type(2) {
      left: 32px;
    }

    &:nth-of-type(3) {
      left: 64px;
    }

    &.icon {
      height: 35px !important;
      width: 32px !important;
      min-width: 32px !important;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  td.options {
    &.icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  tr:hover td.options {
    text-decoration: underline;
  }

  th.options.base,
  td.options.base {
    min-width: 250px;
    max-width: 250px;
    // overflow: hidden;
    &.truncate-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  th.value,
  td.value {
    font-size: 0.7em;
    align-items: center;
    justify-content: center;
    border: 1px solid $gray_light;
    min-width: $cell_width;
    width: $cell_width;
  }

  td.options.base {
    white-space: nowrap;
  }

  &:not(.mini) {
    tr {
      height: 35px;
      &.uploaded > td:not(.value),
      &.actuals > td:not(.value),
      &.indicator > td:not(.value) {
        background: $teal_light;
      }
      &.trendline > td:not(.value) {
        background: $teal_lighter;
      }
      &.forecast > td:not(.value) {
        background: $teal_lightest;
      }
      &.calculated > td:not(.value) {
        background: $teal;
      }
      &.other > td:not(.value) {
        background: $teal_lightest_max;
      }
    }
  }

  &.mini {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll !important;
    * {
      overflow: hidden !important;
      width: auto !important;
      min-width: 0 !important;
      max-width: 1000px !important;
    }

    th,
    td {
      flex: 1;
    }
    .base {
      flex: 6;
      width: auto !important;
      min-width: 0 !important;
      max-width: 350px !important;
    }
  }
}

.scenarios {
  &-load {
    table {
      width: 90%;
      border-collapse: collapse;
      border-spacing: 0;
      border: $borders;
      margin: 3% auto;
      text-align: center;
      thead {
        tr {
          th {
            border: $borders;
            padding: 0.5em;
            text-align: left;
            &:nth-child(3) {
              text-align: center;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            border: $borders;
            padding: 0.5em;
            text-align: left;
            &:nth-child(2) {
              // overflow-x: scroll;
            }
            &:nth-child(3) {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.info-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5em;
  > div {
    width: 40vw;
  }
  mark {
    background-color: $main_secondary_colour;
    padding: 0.2em 0.5em;
    border-radius: 40px;
  }
}

.core-indicators {
  height: 100%;
  overflow: scroll;
  padding-bottom: 1rem;
  .accordion {
    border-left: $borders !important;
    .MuiSlider-root {
      width: 200px;
    }
  }

  .card,
  .accordion-item,
  .accordion-header,
  > *:not(button) {
    border-radius: 0 !important;
    border: none !important;
    background: $background_default;
  }

  .accordion-body {
    border-left: $borders !important;
    padding: 1em 0 1em 1em !important;
    margin: 0 1em !important;
  }

  .accordion-button {
    border-radius: 0 !important;
    font-size: large;
    color: $default_text_color;
  }

  .accordion-button:not(.collapsed) {
    text-decoration: underline;
    background: transparent;
    color: $default_text_color;
    &::after {
      background-image: var(--bs-accordion-btn-icon) !important;
    }
  }

  .accordion-button:focus {
    text-decoration: underline;
    color: $default_text_color;
    background: transparent;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  > section {
    height: 50%;
    overflow-y: scroll;
    h2 {
      font-weight: 900;
      border-top: $borders;
    }
  }

  .parameters {
    margin: 0.5rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    > .flex {
      // targetting slider
      justify-content: flex-start;
      gap: 1rem;
    }
  }
}

.tabbing-switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: center;
  height: 2em;
  z-index: 9;
  top: 33px;
  left: -1px;
  width: 100%;
  overflow-x: scroll;
  // hide any scroll bars
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &.indicator {
    top: 37px;
  }
  &.own {
    top: 2px;
  }
  button {
    min-width: fit-content;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    position: relative;
    top: 1px;
    background: transparent;
    color: $default_text_color;
    &.active {
      text-decoration: underline;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  padding-left: calc(0.5em - 5px);
}

.ticks-wrapper-modal {
  width: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.wrapper-title {
  font-size: 1rem !important;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.updating-scenario-indicators-table {
  height: 500px;
  overflow-y: scroll;
  border: $gray_light 1px solid;
  margin: 1em;
  padding: 1em 1em 0 1em;
  table {
    width: 100%;
  }
}

button.send-update {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: width 0.2s ease-in-out;

  span {
    animation: slideInX 0.4s ease-in-out;
  }

  &:hover {
    background: $gray_lighter;
  }

  svg {
    display: inline-block;
    height: 80%;
    width: auto;
  }

  img {
    display: inline-block;
    height: 80% !important;
    width: auto !important;
  }

  // &.collapsible-open {
  //   animation: slideInX 0.4s ease-in-out;
  // }

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
      -webkit-animation: dash 0.9s ease-in-out;
      animation: dash 0.9s ease-in-out;
    }
    &.line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
      animation: dash 0.9s 0.35s ease-in-out forwards;
    }
    &.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
      animation: dash-check 0.9s 0.35s ease-in-out forwards;
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
}

.scenarios-load {
  max-height: 400px;
  overflow-y: scroll;
  > table {
    width: 90%;
    border-bottom: 1px solid gray;
    td {
      max-width: 210px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

textarea.big {
  width: 100%;
  height: 100px;
}

table.indicators-diplay {
  border: $borders;
  border-collapse: collapse;
  width: $table_width;
  margin: 0 auto;
  max-height: 60vh;
  overflow: scroll;
  th,
  td {
    border: $borders;
    justify-content: center;
    text-align: center;
    width: $table_cell_width;

    &:nth-child(1) {
      width: $title_cell_width;
    }
    // &:nth-child(3) {
    //   background-color: $third_column_color;
    // }
    &:nth-child(4) {
      background-color: $fourth_column_color;
    }
  }
  td > div {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
}

///////////////////////////
///
///
///
///
///////////////////////////

.js-plotly-plot .plotly .modebar-group {
  display: flex !important;
}

///////////////////////////
///
/// Side Panel
///
///////////////////////////

.site-side-panel {
  position: fixed;
  z-index: 0;
  background: $background_default;
  * {
    background: $background_default !important;
    border-color: $background_default !important;
    &:hover {
      background: $background_default !important;
    }
  }

  &.loading {
    animation: moveBackgroundGradient 2s ease-in-out infinite;
    background: linear-gradient(90deg, $gray_dark, $gray_lighter, $gray_dark);
    background-size: 200% 100%;
    z-index: 1;
  }

  .separator {
    background: $gray_light !important;
    width: 95%;
    margin: 0 auto;
    height: 5px !important;
  }

  .indicators-search {
    .indicators-search-results {
      position: absolute;
      margin-left: 1%;
      &.hover-mode {
        position: absolute;
        top: 31px;
      }
    }
    * {
      background: $background_default !important;
      border-color: $background_default !important;
      &:hover {
        background: $background_default !important;
      }
    }
  }

  width: $side_panel_width;
  left: 0;
  top: 0;
  z-index: 2;
  height: 100%;
  border-right: $borders;
  // box-shadow: 0 0 10px $main_primary_colour;
  &-header {
    // box-shadow: 0 0 10px $main_primary_colour;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem;

    .chevrons {
      align-self: flex-start;
      margin-top: 1em;
    }
    .icons {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > button {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        width: 200px !important;
        gap: 0.5rem;
        font-size: 18px;
        line-height: 18px;
        z-index: 1;
        &:hover {
          background: $background_default !important;
          outline: $borders;
        }
        &:first-of-type {
          width: auto !important;
        }
        svg {
          width: 24px !important;
          height: 24px !important;
        }

        .subtitle {
          position: absolute;
          bottom: -17px;
          background: transparent !important;
          font-size: 0.5rem;
          z-index: 0;
          width: 100%;
          text-align: center;
          text-decoration: none !important;
        }
      }
      .logo {
        position: absolute;
        right: 0.5rem;
        width: fit-content !important;
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    height: calc(100vh - $side_panel_header_height - $side_panel_footer_height);
    padding-bottom: $side_panel_footer_height;

    .indicators-search {
      height: 100% !important;
      &-results {
        max-height: 95% !important;
      }
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  &-footer {
    padding: 0.5vh 1.5vh;
    border-top: $borders;
    position: absolute;
    bottom: 0;
    width: 100%;
    // box-shadow: 0 0 10px $main_primary_colour;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    background: $background_default;
  }

  &.collapsed {
    // width: $side_panel_width_collapsed;
    overflow: hidden;
    * {
      max-width: 100%;
    }
    .site-side-panel-header {
      > div {
        gap: 1rem !important;
      }
      .icons > button {
        justify-content: center;
      }
    }
    .site-side-panel-footer {
      flex-direction: column;
      padding: 0.5vw;
      gap: 0.5vw;
      button {
        padding: 0;
      }
      // padding: 0.5vh 1.5vh;
      // border-top: $borders;
      // position: absolute;
      // bottom: 0;
      // width: 100%;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // z-index: 10;
      // background: $background_default;
    }
  }

  .icon-container {
    &-toggle {
      width: 20px;
    }
  }
}

.gif {
  height: max(250px, 35vh);
}

///////////////////////////
///
/// P L A T F O R M    S E A R C H
///
///////////////////////////

.plat-search {
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  &-input {
    width: 100% !important;
    &.active {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  &-results {
    width: 100%;
    overflow: scroll;
    position: absolute;

    top: 27px;
    background: $background_default;
    z-index: 29;
    outline: $main_primary_colour 1px solid;
    // border-top: 1px ;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 1px 2px 3px $main_primary_colour;
  }
  &-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25em 0.5rem;
    > span {
      display: flex;
      justify-content: space-between;
      text-align: left;
    }
  }
}

.indicators-search {
  $component_height: 40px;
  position: relative;
  width: 99%;
  margin-left: 0.5%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // overflow-y: scroll;
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  // hide any scroll bars
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &.inline {
    $component_height: 30px;
    margin-left: 0%;
    * {
      font-size: 0.75rem !important;
    }
  }

  &-input {
    width: 99% !important;
    margin-left: 0.5%;

    border: $borders !important;
    outline: none !important;
    height: $component_height;
    box-shadow: none !important;
    &.active {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-bottom: none !important;
    }
  }

  &.up {
    // This targets .indicators-search.up
    flex-direction: column-reverse;
    position: absolute;
    bottom: 1%;
    border-top: none !important;
  }
  &.up &-input {
    &.active {
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border-top: none !important;
      border-bottom: $borders !important;
    }
  }

  &-results {
    width: 99%;
    overflow-y: scroll;
    margin-left: 0.5%;
    top: 0;
    background: $background_default;
    z-index: 29;
    border: $borders;
    // border-top: 1px ;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none !important;
    &.active {
      min-height: 100px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    // hide any scroll bars
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &.hover-mode {
      position: absolute;
      top: 31px;
    }
  }

  &.up &-results {
    // This targets .indicators-search.up .indicators-search-results
    top: auto; // Remove the top positioning
    bottom: 98%; // Position it above the .indicators-search
    border-bottom-left-radius: 0; // Adjust border-radius
    border-bottom-right-radius: 0;
    border-bottom: none !important;
    border-top: auto;
    border-top-left-radius: 10px; // Add border-radius to the top
    border-top-right-radius: 10px;
    height: 57vh;
  }

  &-result {
    .result {
      display: flex;
      gap: 1rem;
      align-items: center;
      padding: 0.25em 0.5rem;
      > span {
        display: flex;
        justify-content: space-between;
        text-align: left;
        max-width: 78%;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: space-between;
        text-align: left;
      }
      > button {
        width: 20%;
        display: flex;
        align-items: center;
        svg {
          scale: 0.8;
        }
      }
    }

    .subresults {
      width: 97%;
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0 0 0 1%;
      margin-left: 1%;
      border-left: $borders;
      background: $background_default;
      z-index: 29;
      .subresult {
        display: flex;
        gap: 1rem;
        // justify-content: space-between;
        align-items: center;
        padding: 0.25em 0.5rem;
        overflow: scroll;
        width: 100%;
        animation: slideIn 0.3s ease-in-out;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        > span {
          display: flex;
          font-size: 0.95rem;
          max-width: 68%;
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: flex-end;
          gap: 0.5rem;
          text-align: left;
        }
        // > span:nth-of-type(2) {
        //   width: 30%;
        // }
      }
    }
  }
}

.chart-controls {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

input + button.search-reset {
  position: absolute;
  right: 5px;
  top: 2px;
  > img.icon {
    width: 15px !important;
    height: 15px !important;
  }
}

.indicators-view-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  :first-child li > h3 {
    margin-top: 0;
  }

  ol.column-parent {
    overflow-y: scroll;
    height: 100%;
    padding: 0 !important;
    width: 100%;
    overflow-x: scroll;
    margin: 0;
    li {
      list-style: none;
    }
    ol {
      padding: 0 !important;
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  * {
    text-align: left;
  }

  li ol > li {
    padding-left: 1em;
    border-left: $borders;
    h3 {
      margin-left: 1em;
      text-align: left;
      margin-top: 0rem;
      margin-bottom: 0;
    }
    .send-update {
      scale: 0.9;
    }
    h3 + ul {
      border-left: $borders;
      margin-left: 1em;
    }
    &:first-of-type h3 {
      margin-top: 0;
    }
  }
  h3 {
    margin-bottom: 0;
    margin-top: 1rem;
  }
}

.collapsible-side-panel {
  $panel_width: max($side_panel_width, 300px);
  position: fixed;
  top: calc(
    $sticky_header_height + $entity_header_height + 35px + $block_padding
  );
  width: 48%;
  height: calc(
    100vh - $sticky_header_height - $entity_header_height - 35px -
      $block_padding
  );
  background-color: $background_default;
  z-index: 900;
  border: $borders;
  transition: transform 0.3s ease-in-out;

  &.full-screen {
    width: calc(100% - var(--side_panel_with));
    height: calc(100vh - $sticky_header_height);
    top: $sticky_header_height;
  }

  &.right {
    right: 0;
    transform: translateX(100%);
    box-shadow: 0 0 7.5px $gray_darker;

    &.open {
      transform: translateX(0);
    }
  }

  &.left {
    left: var(--side_panel_with);
    transform: translateX(-110%);
    box-shadow: 0 0 7.5px $gray_darker;

    &.open {
      transform: translateX(0);
    }
  }

  &.full {
    height: calc(100vh - $sticky_header_height);
    top: calc($sticky_header_height);
    width: calc((100% - var(--side_panel_with)) / 2 - $block_padding + 3px);
  }

  .panel-content {
    // padding: 30px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  // hide any scroll bars
  scrollbar-width: none;

  .collapsible-side-panel-rules {
    overflow-y: scroll;
    max-height: 200px;
    p,
    span,
    pre,
    strong,
    li {
      font-size: 0.7rem !important;
    }
    h3 {
      font-size: 0.9rem !important;
    }
    h4 {
      font-size: 0.8rem !important;
    }
    h5 {
      font-size: 0.7rem !important;
    }
    h6 {
      font-size: 0.7rem !important;
    }
  }

  .collapsible-side-panel-body {
    overflow-y: scroll;
    max-height: 63vh;
    // hide any scroll bars
    scrollbar-width: none;
  }
}

.panel-comparison {
  position: relative;
  &-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: 0px;
  }
  &-data {
    .entity-parameters {
      position: sticky !important;
      top: 0;
      z-index: 20;
    }
  }
  .data-mapped {
    position: relative;
    top: 0px;
  }
}

#gpt-icon {
  cursor: pointer;
}

.gpt-active {
  display: block !important;
}

.gpt-panel {
  padding: 1.5vh;
  &.float {
    position: fixed;
    border: 2px solid $main_primary_colour;
    background: #dc8100;
    z-index: 5001;
    width: min(400px, 80vw);
    height: 80%;
    top: 50px;
    right: 40px;
    border-radius: 10px;

    &::before {
      content: '';
      position: absolute;
      top: -25px;
      right: 5px;
      border: 12px solid transparent;
      border-bottom: 12px solid $main_primary_colour;
    }
    &::after {
      content: '';
      position: absolute;
      top: -23px;
      right: 5px;
      border: 12px solid transparent;
      border-bottom: 12px solid $main_secondary_colour;
    }
    .chatbot-container {
      .user-input {
        position: fixed;
        bottom: 17vh;
        right: 60px;
      }
    }
  }

  &-header {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    color: $main_primary_colour;
    height: 25%;
    * {
      color: $background_default;
    }
  }

  &-body {
    background-color: $background_default;
    height: 75%;
    border-radius: 10px;
    padding: 1em;
    overflow-y: scroll;
    width: min(calc(400px - 3vh), calc(80vw - 3vh));
    .chatbot-container {
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
}

.custom-context-menu {
  position: fixed;
  background-color: $background_default;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  z-index: 10000;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .submenu-items {
    position: absolute;
    background-color: $background_default;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    z-index: 10001;
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

// .site-search-page {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   padding: 1rem;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: flex-start;
//   &-input {
//     width: 100% !important;
//     &.active {
//       border-bottom-left-radius: 0 !important;
//       border-bottom-right-radius: 0 !important;
//     }
//   }
//   &-results {
//     width: 100%;
//     height: auto;
//     position: absolute;
//     top: 33px;
//     background: $background_default;
//     z-index: 29;
//     outline: $main_primary_colour 2px solid;
//     // border-top: 1px ;
//     border-bottom-left-radius: 10px;
//     border-bottom-right-radius: 10px;
//     box-shadow: 1px 2px 3px $main_primary_colour;
//   }
//   &-result {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0 0.5rem;
//     > span {
//       display: flex;
//       justify-content: space-between;
//     }
//   }
// }

.advanced-search {
  width: 100%;
  margin: 0;
  min-width: 250px;
  height: 100%;

  h1 {
    margin-bottom: 1.5rem;
    text-align: center;
  }

  input {
    margin: 5px;
    width: 50%;
  }

  .results {
    height: 70%;
    overflow-y: scroll;
    margin-top: 1rem;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    .special-list-item {
      margin: 0;
      padding: 5px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span.type {
        font-size: 0.7rem;
        position: relative;
        margin-left: 1rem;
        @media (min-width: 768px) {
          position: absolute;
          left: 70%;
          margin: 0;
        }
      }

      span.details {
        font-size: 0.7rem;
        position: relative;
        margin-left: 1rem;
        @media (min-width: 768px) {
          position: absolute;
          left: 60%;
          margin: 0;
        }
      }

      span.avs-tags {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        flex-wrap: nowrap;
        white-space: nowrap; /* prevent wrapping of content */
        overflow: hidden; /* hide the overflow */
        text-overflow: ellipsis;
        width: 30%;
        font-size: 0.7rem;
        opacity: 0.7;
        position: relative;
        margin-left: 1rem;
        @media (min-width: 768px) {
          position: absolute;
          left: 35%;
          margin: 0;
        }
        svg {
          width: 20px;
          height: 20px;
          fill: rgba(0, 0, 0, 0.7);
        }
        span {
          white-space: nowrap; /* prevent wrapping of content */
          overflow: hidden; /* hide the overflow */
          text-overflow: ellipsis;
        }
      }

      span.icon {
        width: 20px;
        height: 20px;
        @media (min-width: 768px) {
          position: absolute;
          right: 1rem;
          margin: 0;
        }
      }
    }
  }
}

.new {
  &.entity-page {
    padding: 1rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    .header {
      margin-bottom: 1rem;
      .title {
        font-size: 30px;
        line-height: 32px;
        font-weight: 900;
        color: $default_text_color;
      }
      .subtitle {
        font-weight: 900;
        font-size: 1rem;
      }
    }
  }

  &.memo {
    overflow: hidden;
    height: 100vh;
    .special-list-item {
      overflow: visible;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.tags-list {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  .tag {
    display: flex;
    padding: 0.25rem;
    gap: 0.25rem;
    align-items: center;
  }
}

$toggle-width: 35px;
$toggle-height: calc($toggle-width / 2);
$slider-dimension: calc($toggle-height - ($toggle-height * 0.2));
$slider-move-distance: calc(
  $toggle-width - $slider-dimension - 2 * ($toggle-height * 0.1)
); // Left and right padding

.toggle-container {
  width: $toggle-width;
  height: $toggle-height;
  background-color: #ccc;
  position: relative;
  border-radius: calc(
    $toggle-height / 2
  ); // Keeping the ratio for rounded edges
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &.loading {
    background-color: #fce9ce;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.toggle-wrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 35px;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
    * {
      cursor: not-allowed !important;
    }
  }

  .toggle-title {
    font-size: 0.875rem;
  }
}

.slider {
  position: absolute;
  top: 50%; // Adjusted to center the slider
  left: $toggle-height * 0.1; // A bit of margin for aesthetics
  width: $slider-dimension;
  height: $slider-dimension;
  background-color: white;
  border-radius: 50%;
  transition: 0.3s;
  transform: translateY(-50%); // Adjusted to center the slider
}

.slider.enabled {
  transform: translate(
    $slider-move-distance,
    -50%
  ); // Movement in the x direction based on available space
}

.slider.loading {
  transform: translate(calc($slider-move-distance * 0.33), -50%);
  background-color: #f0ad4e;
}

.upload-page-body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap !important;
  overflow: scroll;
  gap: 0.75rem;
  max-height: 50%;
  align-items: flex-start;
  padding: 0.5rem;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.combine-indicator-formula {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0.5rem;
  width: 100%;
  overflow: scroll;
  align-items: center;
  font-size: 0.8rem;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  // hide scrollbar for IE, Edge and Firefox
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  .indicator-buttons {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  button {
    text-align: center;

    border: none;
    border-radius: 5px;
    background-color: #f2f2f2;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition:
      background-color 0.3s ease-in-out,
      transform 0.1s ease;

    &:hover {
      background-color: #e6e6e6;
    }

    &:active {
      box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.2);
    }
  }

  .indicator-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 50%;
    width: 100%;
    overflow: scroll;
  }

  .operation-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .control-buttons {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      padding: 0.1rem !important;
      gap: 0.5rem;
      button {
        width: 70px;
        font-size: 0.7rem;
        height: 25px;
        padding: 0.1rem;
        * {
          margin: 0;
        }
      }
    }
  }

  .equation-display {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    background-color: #fff;
    box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.1);
    font-size: 1.2em;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    .indicator-box {
      position: relative;
      width: fit-content;
      display: flex;
      // align-items: flex-start;
      // justify-content: center;
      flex-direction: column;

      .indicator {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
        padding: 0 !important;
        margin: 0 0.5em !important;
        text-overflow: ellipsis;
        text-align: center;
      }
      .settings {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 0.25rem;
        scale: 0.7;
        position: relative;
        left: -15%;
        button {
          scale: 0.8;
        }
      }
    }
    .operator {
      border: none !important;
      max-width: fit-content;
      box-shadow: none !important;
      padding: 0 0 0 0.5em !important;
      margin: 0 0.5em !important;
    }
  }
}

button.download-chart {
  margin: 0;
  img {
    height: 15px !important;
    width: 15px !important;
  }
}

button.capture-chart {
  svg {
    height: 20px !important;
    width: 20px !important;
  }
}

.gap-analysis {
  &-settings {
    display: flex;
    padding: 0.5em;
    align-items: center;
    gap: 1em;
    width: 100%;
    flex-wrap: wrap;
  }
  &-explanation {
    font-size: 0.7rem;
    padding: 0.5em;
  }
  &-answer {
    display: flex;
    padding: 0.5em;
    align-items: center;
    gap: 1em;
    font-size: 1.5rem;
  }
}

.collection-table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    text-align: left;
    padding: 8px;
  }

  thead {
    background-color: #f4f4f4;

    th {
      font-weight: bold;
    }
  }

  .collection,
  .scenario,
  .indicator {
    &:nth-child(odd) {
      background-color: #f9f9f9;
    }

    &:nth-child(even) {
      background-color: #fff;
    }
  }

  th,
  td {
    border-bottom: 1px solid #ddd;
  }

  .pointer {
    cursor: pointer;
  }

  .danger {
    color: #ff6347; // Tomato color for danger actions
  }

  // Customizing the width of table columns
  th,
  td {
    &:nth-child(1):not(th) {
      width: 15%;
      cursor: pointer;
      &:hover {
        background-color: $gray_light;
      }
    }

    &:nth-child(2) {
      width: 50%; // Largest width for Details column
      // limit the amount of text shown by using ellipsis
      max-width: 25vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:nth-child(3),
    &:nth-child(4) {
      width: 5%;
    }
  }
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #f2f2f2;
}

.login-form {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.form-field {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-input {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.login-button {
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.register-text {
  text-align: center;
  margin-top: 20px;
  color: #666;
}

.error-text {
  color: red;
  text-align: center;
  margin-top: 15px;
}

.form-input::placeholder {
  color: #aaa;
}

.separator {
  border: $borders;
  height: 2px;
  width: 100%;
}

.global-settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .tabbing-switch {
    font-size: 1.2em;
  }
  > div {
    max-height: 100%;
    overflow-y: scroll;
    // to hide the scrollbar
    &::-webkit-scrollbar {
      width: 0;
    }
    @media (max-width: 768px) {
      overflow-y: visible;
    }
  }
}

@keyframes expandBorder {
  0% {
    border-color: transparent !important;
  }
  100% {
    border-color: $gray_dark !important;
  }
}

@keyframes bolden {
  0% {
    font-weight: 400;
  }
  100% {
    font-weight: bolder;
  }
}

.tabbing-switch-new {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: center;
  top: 0 !important;
  height: 33px !important;
  border: none !important;
  width: 100%;
  overflow-x: hidden;
  // hide any scroll bars
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.breadcrumbs {
    display: flex;
    width: 100%;
    height: 20px !important;
    justify-content: flex-start;
    button.tabbing-switch-option {
      height: 20px !important;
      font-size: 0.8rem;
      &:first-of-type {
        margin-left: 0rem;
      }

      &.active,
      &:hover {
        border-bottom: $borders !important;
      }
    }
  }

  button.tabbing-switch-option {
    width: fit-content;
    min-width: fit-content;
    max-width: 40% !important;
    border-radius: 0 !important;
    border: none;
    border-bottom: transparent 3.5px solid !important;
    position: relative;
    height: 35px !important;
    background: transparent;
    color: $default_text_color;
    transition: 0.15s ease-in-out;
    cursor: pointer !important;
    margin: 0 0.25rem;
    padding: 0;
    display: flex;
    align-items: center;

    // &.special {
    //   height: 30px !important;
    //   padding: 0 0.25em;
    //   border-radius: 10px 10px 0 0 !important;
    //   background: $gray_light;
    // }

    span {
      margin-bottom: -6px;
      width: 100% !important;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px !important;
    }

    &.active {
      border-color: $gray_dark !important;
      animation: expandBorder 0.15s ease-in-out;
      span {
        font-weight: 900;
        animation: bolden 0.15s ease-in-out;
      }
    }

    &.tabbing-look {
      background-color: $gray_lighter;
      margin: 0.25rem;
      padding: 0 0.5rem;
      border-top-left-radius: 15px !important;
      border-top-right-radius: 15px !important;
      height: 25px !important;
      position: relative;
      bottom: -2px;

      &:hover,
      &.active {
        border-color: $gray_light !important;
        background: $gray !important;
        span {
          font-weight: bold !important;
        }
      }
    }

    &:hover {
      border-color: $gray_dark !important;
      animation: expandBorder 0.15s ease-in-out;
      span {
        font-weight: 900;
        animation: bolden 0.15s ease-in-out;
      }
    }

    &.icon {
      transition: none !important;
      padding: 0;
      width: 20px !important;
      min-width: 20px !important;
      svg {
        scale: 0.75;
        transition: none !important;
        position: relative;
        top: 2px;
      }
      &:disabled {
        transition: none !important;
        cursor: not-allowed !important;
        background: transparent !important;
        border-color: transparent !important;
        svg {
          fill: $gray_dark;
        }
      }
    }
  }
}

.admin-page {
  .query-section,
  .result-section,
  .presets-section {
    min-height: 20vh;
    margin: $block_padding;
    padding: $block_padding;
    border: $borders;
  }
}
.tag-buttons {
  flex-wrap: wrap;
  padding: 0.5rem 0;
  .tag-button {
    svg {
      height: 100%;
    }
  }
}

.news-container {
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  padding: 0 $block_padding $block_padding $block_padding;

  &::-webkit-scrollbar {
    width: 0;
  }

  &.full {
    max-height: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    text-align: left;
    padding: 4px;
    border-bottom: $borders;

    &.large {
      width: 50%;
    }
  }

  tr {
    &:hover {
      background-color: darken($background_default, 10%);
    }
    &.in-progress {
      background-color: $yellow_lighter;
      opacity: 0.6;
    }
    &.live-testing {
      background-color: $blue_light;
      opacity: 0.8;
    }
  }

  td {
    &.link {
      a {
        color: #1a0dab;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.file-upload {
  color: black;
  gap: 0.2rem;
  display: flex;
  align-items: center;
}

.file-upload svg {
  scale: 0.8;
  path {
    stroke: black;
  }
}

.file-input {
  background-color: transparent;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

#file-input {
  display: none;
}

.file-upload > label {
  height: 30px;
  line-height: 35px;
  margin-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.file-upload.disabled,
.file-upload.disabled path,
.file-upload.disabled label {
  cursor: not-allowed !important;
  opacity: 0.5;
  color: #666 !important;
  stroke: #666;
}

.memo-block {
  position: relative;
  width: 100%;

  .controls {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    width: calc(100% - 1rem);
  }

  .image img {
    padding-top: 45px;
    width: 100%;
  }

  textarea {
    padding: 35px 0.5rem 0 !important;
  }

  .drag-handle {
    cursor: grab; /* Changes the cursor to indicate it's draggable */
    width: 30px; /* Adjust if you want the handle to be less wide than the memo block */
    display: flex;
    align-items: center;
    border-top-left-radius: 4px; /* Rounded corners at the top */
    border-top-right-radius: 4px;
    user-select: none; /* Prevents text selection during drag */
    span {
      font-weight: 900;
      font-size: 1.5rem;
      text-align: left;
    }
  }

  .drag-handle:active {
    cursor: grabbing;
  }
}

table.sharing-references {
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;

    img {
      max-height: 150px;
      width: auto;
    }
  }
  th {
    background-color: #f4f4f4;
  }
  tr {
    &:nth-child(odd) {
      background-color: #f9f9f9;
    }
    &:nth-child(even) {
      background-color: #fff;
    }
  }
}

.thumbnails {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-evenly;
  $thumbnails_in_row: 3;
  $thumbnails_margin: 1.5%;

  &.count-3 {
    $thumbnails_in_row: 3;
    $thumbnails_margin: 1.5%;
    .thumbnail {
      margin: $thumbnails_margin;
      width: calc(
        (100% - ($thumbnails_margin * $thumbnails_in_row * 2)) /
          $thumbnails_in_row
      );
    }
  }

  &.count-4 {
    $thumbnails_in_row: 4;
    $thumbnails_margin: 1.5%;
    .thumbnail {
      margin: $thumbnails_margin;
      width: calc(
        (100% - ($thumbnails_margin * $thumbnails_in_row * 2)) /
          $thumbnails_in_row
      );
    }
  }

  &.count-5 {
    $thumbnails_in_row: 5;
    $thumbnails_margin: 1.5%;
    .thumbnail {
      margin: $thumbnails_margin;
      width: calc(
        (100% - ($thumbnails_margin * $thumbnails_in_row * 2)) /
          $thumbnails_in_row
      );
    }
  }

  &.count-6 {
    $thumbnails_in_row: 6;
    $thumbnails_margin: 1.5%;
    .thumbnail {
      margin: $thumbnails_margin;
      width: calc(
        (100% - ($thumbnails_margin * $thumbnails_in_row * 2)) /
          $thumbnails_in_row
      );
    }
  }

  .thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative; // Добавляем позиционирование relative
    gap: 0.5rem;
    margin: $thumbnails_margin;
    width: calc(
      (100% - ($thumbnails_margin * $thumbnails_in_row * 2)) /
        $thumbnails_in_row
    );
    padding: 0.5rem;
    border-radius: 15px;
    background-color: $background_default;
    box-shadow: 1px 3px 6px $gray !important;

    @media (max-width: 768px) {
      width: 98% !important;
    }

    &-edit {
      > img {
        width: 100%;
        max-width: 300px;
        height: 130px;
        object-fit: contain;
      }
    }

    .owner-info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 0rem;
      position: absolute;
      top: 0px;
      left: 0px;
      scale: 0.8;
      z-index: 2; // Поверх тегов
      width: 30px;

      .image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      span {
        font-size: 0.7rem;
        line-height: 0.7rem;
      }
    }

    .tags {
      position: absolute;
      // top: 0;
      left: 0;
      display: flex;
      max-width: 20%;
      flex-wrap: wrap;
      gap: 0.5rem;
      align-items: center;
      font-size: 0.8rem;
      z-index: 1;
      opacity: 0.7;

      span {
        font-size: 0.7rem;
        color: $red;
        opacity: 0.7;
      }
      svg {
        width: 20px;
        height: 20px;
        fill: rgba(0, 0, 0, 0.7);
      }
    }

    > img,
    > svg,
    .canvas-frame {
      width: 100%;
      max-width: 300px;
      height: 130px;
      object-fit: contain;
    }
    .details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      * {
        margin: 0;
      }
      .titles {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        .title {
          font-size: 1.15rem;
          line-height: 1.25rem;
          font-weight: 600;
          overflow: hidden;
          max-height: max(8vw, 2.5rem);
          cursor: pointer;
          width: 92%;
          color: $main_primary_colour;
          &:hover {
            text-decoration: underline;
          }
        }
        .subtitle {
          font-size: 0.7rem;
          font-weight: 400;
        }
      }
      .description {
        font-size: 0.9rem;
        font-weight: 400;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    button.edit {
      position: absolute;
      top: 0px;
      right: 0px;
      opacity: 0.6;
      scale: 0.8;
      z-index: 1;
    }

    &.new {
      cursor: pointer;
      .details {
        .titles {
          .title {
            color: $main_secondary_colour;
          }
        }
      }
    }
    .image-container {
      width: 100%;
      height: 0;
      padding-bottom: 75%;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
    }

    .image-container img {
      max-width: 300px;
      width: 100%;
      height: 130px;
      object-fit: contain;
      display: block;
      // margin: 3em auto;
      top: 2em;
    }

    .image-container .image-placeholder,
    .image-container .canvas-word {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-placeholder svg,
    .canvas-word {
      max-width: 300px;
      width: 100%;
      height: 130px;
      object-fit: contain;
    }

    .flip-card {
      width: 100%;
      height: auto;
      perspective: 1000px;
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 75%;
      transition: transform 0.6s;
      transform-style: preserve-3d;
    }

    .image-container:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      max-width: 300px;
      width: 100%;
      height: 130px;
      object-fit: contain;
      top: 1em !important;
      backface-visibility: hidden;
      border-radius: 10px;
    }
    .flip-card-back {
      background-color: $gray;
      color: white;
      transform: rotateY(180deg);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px;
      box-sizing: border-box;
    }
    .description {
      font-size: 0.8rem;
    }
  }
}

.profile {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .profile-image {
    flex: 0 0 auto;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .profile-info {
    flex: 1;

    .name {
      font-size: 24px;
      color: #333;
      margin: 0 !important;
      border: 0 !important;
      width: 50% !important;
      height: 50px !important;
    }

    .username {
      font-size: 18px;
      color: #666;
      margin: -10px 0 0;
    }
  }
}

.search-select {
  position: relative;
  display: inline-block;

  input {
    width: 100%;
    padding: 8px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .options-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: revert-layer;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 150px;
    overflow-y: auto;
    background: #fff;
    box-sizing: border-box;

    li {
      padding: 8px;
      cursor: pointer;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}

.canvas-frame {
  border: 5px solid #4b4897;
  border-radius: 10px;
  width: 70% !important;
  margin: 15% auto !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  animation:
    fadeIn 2s ease-in-out,
    pulse 3s infinite;

  display: block;
  margin: 0 auto;
}

// recharts
.recharts-legend-item-text {
  font-size: 0.6rem;
}

.entities-container {
  margin: 20px;

  .search-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    box-sizing: border-box; // Ensures the padding does not affect the width
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .entities-table {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      text-align: left;
      padding: 10px;
      border-bottom: 1px solid #eee;
    }
    th {
      background-color: #f4f4f4;
    }
    tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  }
}

.data-table {
  &-values {
    position: relative;
    border: none;
    &-scroll-container {
      overflow-x: auto;
      white-space: nowrap;
    }
    td {
      border: $borders;
      border-top: none;
      font-size: 0.75rem;
      &:first-of-type {
        border-left: none;
      }
    }
    &__key {
      font-weight: bold;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: sticky;
      left: 0;
      background-color: #fff;
    }
    &__value {
      min-width: 55px;
      max-width: 55px;
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &-parameters {
    th {
      border: $borders;
      border-top: none;
      font-size: 0.75rem;
    }
    td {
      height: 25px;
      max-width: 200px;
      border: $borders;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-top: none;
      font-size: 0.75rem;
    }
  }
}
